import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import typography from "assets/theme/base/typography";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import config from "config";

export default function Footer() {
  const { size } = typography;

  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const menus = [
    { name: t("Support"), to: "/support" },
    { name: t("Customer log in"), to: "/u" },
    {
      name: t("Pricing"),
      onClick: () => {
        navigate("/#pricing");
        setTimeout(
          () => document.getElementById("pricing")?.scrollIntoView(),
          100
        );
      },
    },
    { name: t("Privacy"), to: "/support/privacy" },
  ];

  return (
    <MDBox
      m="auto"
      maxWidth="lg"
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      py={10}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        {t(
          "{{name}} is designed, built, and backed by {{companyName}}. Enjoy the rest of your {{weekday}}!",
          {
            name: config.name,
            companyName: config.companyName,
            weekday: new Date().toLocaleDateString(i18n.language, {
              weekday: "long",
            }),
          }
        )}
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {menus.map(({ to, onClick, name }) => (
          <MDBox key={name} component="li" px={2} lineHeight={1}>
            {to ? (
              <Link to={to}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {name}
                </MDTypography>
              </Link>
            ) : (
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={onClick}
                sx={{ cursor: "pointer" }}
              >
                {name}
              </MDTypography>
            )}
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  );
}
