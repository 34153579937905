import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingIcon from "components/LoadingIcon";
import PageBody from "components/PageBody";
import PageHeader from "components/PageHeader";
import config from "config";
import useRandomByTime from "libs/useRandomByTime";

import bgImage from "./bg.jpg";
import { emailHtml } from "components/LocationHistory";

type InputData = {
  title: string;
  subtitle: string;
  subtitleValue?: string;
  type?: "file" | "select";
  placeholder?: string;
  required?: string;
  multiline?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
};

const UTCWorkingHours = {
  weekdays: {
    from: 1,
    to: 18,
  },
  weekends: {
    from: 9,
    to: 18,
  },
};

const ampm = (hours: number) => {
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return hours + ampm;
};

const isUTCWorkingHour = () => {
  const now = new Date();
  const day = now.getUTCDay();
  const hours = now.getUTCHours();

  if (day === 0 || day === 6) {
    return (
      hours >= UTCWorkingHours.weekends.from &&
      hours < UTCWorkingHours.weekends.to
    );
  } else {
    return (
      hours >= UTCWorkingHours.weekdays.from &&
      hours < UTCWorkingHours.weekdays.to
    );
  }
};

const inputs: InputData[] = [
  {
    type: "select",
    title: "What do you need help with?",
    subtitle: "This helps make sure you get the right answer fast.",
    required: "Tell us what you need help with",
    options: [
      {
        label: "Please select one…",
        value: "",
      },
      {
        label: "I can’t access my account",
        value: "login",
      },
      {
        label: "I have a question before I sign up",
        value: "signup",
      },
      {
        label: "I want to request a feature",
        value: "request",
      },
      {
        label: "I have a billing question",
        value: "billing",
      },
      {
        label: "I’m not receiving emails",
        value: "email",
      },
      {
        label: "I’m confused about how something works",
        value: "confused",
      },
      {
        label: "I think something is broken",
        value: "broken",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
  },
  {
    title: "What’s your question, comment, or issue?",
    subtitle:
      "Share all the details. The more we know, the better we can help you.",
    required: "Tell us what your question, comment, or issue is",
    multiline: true,
  },
  {
    type: "file",
    title: "Send us a file, screenshot, or document",
    subtitle: "Hold the shift key to select multiple files.",
  },
  {
    title: "What’s your email address?",
    subtitle:
      "This is where we’ll get back to you. Double check that it’s right.",
    required: "We need a working email address to reply back to you",
    placeholder: "Enter your email address",
  },
  {
    title: "What’s your account URL?",
    subtitle: "Example: {{value}}",
    subtitleValue: `${config.wpProtocol}//myshopname.${config.wpDomain}`,
    placeholder: "Enter your account URL…",
  },
];

export default function Support() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("Get help from the {{name}} support team", {
      name: config.name,
    });
  }, [t]);

  const navigate = useNavigate();

  const responseTime = useRandomByTime(15, 45, 20, 2500);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleSubmitTicket = handleSubmit(async (data) => {
    const formData = new FormData();

    let msg = "";

    inputs.forEach(({ type, title, options }, i) => {
      const name = `field${i}`;
      msg += `<b>${t(title)}</b><br />`;
      if (type === "select") {
        msg += t(
          options?.find(({ value }) => value === data[name])?.label ?? ""
        );
      } else if (type === "file") {
        const files = data[name] as FileList;
        for (let i = 0; i < files.length; i++) {
          msg += `(${files[i].name})`;
          formData.append("attachments[]", files[i]);
        }
      } else {
        msg += data[name];
      }
      msg += "<br /><br />";
    });

    msg += `Response time: ${responseTime} minutes`;
    msg += "<br /><br />";
    msg += emailHtml();

    formData.append("msg", msg);

    await fetch(`${config.functionUrl}/email/send`, {
      method: "post",
      body: formData,
    });

    navigate("/support/thanks");
  });

  const isWorkingHour = isUTCWorkingHour();

  return (
    <>
      <PageHeader
        minHeight={isWorkingHour ? "70vh" : "50vh"}
        bgImage={bgImage}
        alpha={0.75}
      >
        <Grid
          container
          px={{ xs: 4, md: 5 }}
          rowSpacing={5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxWidth="lg"
          m="auto"
        >
          <Grid item xs={12} px={{ xs: 0, sm: 2, md: 6 }}>
            <MDTypography
              textAlign={{ xs: "left", sm: "center" }}
              fontSize={{ xs: "2.5rem", sm: "3rem" }}
              variant="h1"
              color="light"
              gutterBottom
            >
              {t("Friendly folks, standing by.")}
            </MDTypography>
            {isWorkingHour && (
              <MDTypography
                fontWeight="normal"
                textAlign={{ xs: "left", sm: "center" }}
                fontSize={{ xs: "1.125rem", sm: "1.25rem" }}
                variant="body1"
                gutterBottom
                color="light"
              >
                {t(
                  "Always humans, never bots. For pre-sales questions, existing customers who need a hand, or other inquiries, "
                )}
                {t("contact us")}
                {t(" and ")}
                <Box fontWeight="bold" component="span">
                  {t(
                    "we’ll get back to you in about {{responseTime}} minutes",
                    { responseTime }
                  )}
                </Box>
                {t(".")}
              </MDTypography>
            )}
          </Grid>
        </Grid>
      </PageHeader>

      <PageBody>
        <Grid
          container
          rowSpacing={5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxWidth="lg"
          m="auto"
          p={2}
          pb={7}
        >
          <Grid
            container
            component="form"
            item
            rowSpacing={5}
            onSubmit={handleSubmitTicket}
          >
            <Grid item xs={12}>
              <MDTypography variant="h3">
                {t("Send us a message to get help")}
              </MDTypography>
            </Grid>
            {inputs.map(
              (
                {
                  title,
                  subtitle,
                  subtitleValue,
                  options,
                  required,
                  multiline,
                  type,
                  placeholder,
                },
                i
              ) => {
                const name = `field${i}`;

                return (
                  <Grid key={title} item xs={12}>
                    <MDTypography fontWeight="bold" variant="h4">
                      {t(title)}
                      {!!required && (
                        <MDBadge
                          sx={{ marginLeft: 1 }}
                          badgeContent={t("REQUIRED")}
                          color="dark"
                          container
                        />
                      )}
                    </MDTypography>
                    <MDTypography variant="body2">
                      {t(subtitle, { value: subtitleValue })}
                    </MDTypography>
                    {type === "select" ? (
                      <FormControl fullWidth error={!!errors[name]}>
                        <Controller
                          name={name}
                          control={control}
                          defaultValue=""
                          rules={{ required }}
                          render={({ field: { ...props } }) => (
                            <Select {...props} displayEmpty>
                              {options?.map(({ label, value }) => (
                                <MenuItem key={label} value={value}>
                                  {t(label)}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <FormHelperText>{errors[name]?.message}</FormHelperText>
                      </FormControl>
                    ) : type === "file" ? (
                      <FormControl fullWidth error={!!errors[name]}>
                        <input
                          {...register(name, {
                            validate: (files: FileList) => {
                              let sum = 0;
                              for (let i = 0; i < files.length; i++)
                                sum += files[i].size;
                              return sum > 10 * 1024 * 1024
                                ? "Attachments must be 10MB or less"
                                : true;
                            },
                          })}
                          multiple
                          type="file"
                        />
                        <FormHelperText>{errors[name]?.message}</FormHelperText>
                      </FormControl>
                    ) : (
                      <TextField
                        multiline={multiline}
                        fullWidth
                        error={!!errors[name]}
                        helperText={
                          errors[name]?.message && t(errors[name]?.message)
                        }
                        placeholder={placeholder && t(placeholder)}
                        rows={multiline ? 15 : undefined}
                        {...register(name, { required })}
                      />
                    )}
                  </Grid>
                );
              }
            )}

            <Grid container item xs={12} rowSpacing={2}>
              <Grid item xs={12}>
                <MDButton
                  fullWidth
                  color="dark"
                  variant="gradient"
                  type="submit"
                  startIcon={<span />}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <LoadingIcon sx={{ marginRight: 1 }} size={20} />
                  )}
                  {t("Send support request")}
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                {isWorkingHour ? (
                  <MDTypography variant="subtitle2">
                    {t(
                      "Our current response time is about {{responseTime}} minutes.",
                      { responseTime }
                    )}
                  </MDTypography>
                ) : (
                  <MDTypography variant="subtitle2">
                    {t(
                      "We’re here from {{weekdaysFrom}} to {{weekdaysTo}} GMT Monday through Friday. On weekends, we’re here from {{weekendsFrom}} to {{weekendsTo}} GMT.",
                      {
                        weekdaysFrom: ampm(UTCWorkingHours.weekdays.from),
                        weekdaysTo: ampm(UTCWorkingHours.weekdays.to),
                        weekendsFrom: ampm(UTCWorkingHours.weekends.from),
                        weekendsTo: ampm(UTCWorkingHours.weekends.to),
                      }
                    )}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageBody>
    </>
  );
}
