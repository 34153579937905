import api from "services/wc/_api";

export type TShippingZoneLocation = {
  code: string;
  type: string;
};

export type TShippingZoneLocationsResponse = TShippingZoneLocation[];

const shippingZoneMethods = {
  get: async (zone: number): Promise<TShippingZoneLocationsResponse> =>
    (await api.get(`/shipping/zones/${zone}/locations`)).data,

  put: async (zone: number, data: TShippingZoneLocation[]): Promise<{}> =>
    (await api.put(`/shipping/zones/${zone}/locations`, data)).data,
};

export default shippingZoneMethods;
