import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PriceCard from "components/PriceCard/PriceCard";
import MDTypography from "components/MDTypography";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import config from "config";

export default function PricingSection() {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // @ts-ignore: Event snippet for eShopRocket Price Button Conversion conversion page
        gtag("event", "conversion", {
          send_to: "AW-965382839/HnxGCPjW5KYZELelqswD",
        });
      }
    });

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Box ref={ref} id="pricing" pt={15}>
      <Container
        sx={{
          bgcolor: {
            xs: "#111322",
            sm: "rgba(0,0,0,0)",
          },
        }}
      >
        <Box
          bgcolor="#111322"
          borderRadius="1rem"
          px={{ xs: 1, sm: 3 }}
          pt={4}
          pb={8}
        >
          <Grid
            container
            rowSpacing={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            maxWidth="lg"
            m="auto"
          >
            <Grid item xs={12}>
              <MDTypography
                variant="h2"
                fontSize={{ xs: "1.875rem", sm: "2.0625rem", md: "2.25rem" }}
                textAlign="center"
                gutterBottom
                color="light"
              >
                {t("Pricing")}
              </MDTypography>

              <Typography variant="subtitle1" textAlign="center" color="#fff">
                {t("Two simple plans, each with a ")}
                <Box component="span" fontWeight="bold">
                  {t("30-day free trial.")}
                </Box>{" "}
                {t("No credit card required.")}
              </Typography>
            </Grid>

            <Grid item container justifyContent="center">
              <Grid
                item
                xs={12}
                lg={7}
                display="flex"
                alignItems="center"
                flexDirection="column"
                order={{ xs: 1, lg: 2 }}
              >
                <Box
                  component="img"
                  width={250}
                  src={require("./gold-globe.webp")}
                />
                <Box mt="-8rem" width="100%">
                  <PriceCard gold button />
                </Box>
              </Grid>

              <Grid
                item
                mt={{ xs: 5, lg: 9.75 }}
                xs={12}
                lg={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                order={{ xs: 2, lg: 1 }}
              >
                <PriceCard button hideLgBorderRight />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              {[
                {
                  title: t("No Upfront Fees."),
                  description: t(
                    "We offer mobile app and website development services without any upfront costs."
                  ),
                },
                {
                  title: t("Develop any new features, no additional charges."),
                  description: t(
                    "Enjoy unlimited, custom feature updates crafted by professional developers, not just plugins or templates."
                  ),
                },
                {
                  title: t("Risk-free, cancel anytime, no long-term lock-in."),
                  description: t(
                    "With {{name}}, cancellation is entirely self-serve, no questions asked, no retention specialists trying to talk you out of it. Cancel any time, no long-term contracts to lock you in. Simple, straightforward, and fair, just as it should be.",
                    { name: config.name }
                  ),
                },
                {
                  title: t("Real human tech support."),
                  description: t(
                    "Real human tech support is available in Cantonese and English from 8:00 AM to 11:59 PM HKT via WhatsApp and telephone, ensuring you get the help you need when you need it."
                  ),
                },
              ].map(({ title, description }, i) => (
                <Stack key={title} alignItems="center">
                  <Typography
                    variant="h3"
                    color="#fff"
                    textAlign="center"
                    gutterBottom
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#fff"
                    textAlign="center"
                    sx={{ opacity: 0.8 }}
                    gutterBottom
                  >
                    {description}
                  </Typography>
                  <Divider sx={{ bgcolor: "#fff", my: 4, width: 200 }} />
                </Stack>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
