import md5 from "md5";
import { useEffect, useState } from "react";

export default function useRandomByTime(
  from: number,
  to: number,
  defaultValue: number,
  delay: number
) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (unmounted) return;
      const now = new Date();
      const s = md5(
        `${now.getMonth()}/${now.getDate()} ${now.getHours()}:${now.getMinutes()}`
      );
      const d = 4;
      setValue(
        from +
          Math.floor(
            (parseInt(s.substring(0, d), 16) / Math.pow(16, d)) *
              (to - from + 1)
          )
      );
    }, delay);

    return () => {
      unmounted = true;
    };
  }, [defaultValue, delay, from, to]);

  return value;
}
