import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FixHeightWPImage from "components/FixHeightWPImage";
import ProductCategoryAutocomplete from "components/ProductCategoryAutocomplete";
import AddSlideButton from "./AddSlideButton";
import { TLayout } from "services/core/layouts";
import { Control, useFieldArray } from "react-hook-form";

const height = 120;

export default function SlidesEditor({
  control,
  name,
}: {
  control: Control<TLayout>;
  name:
    | "popup.slides"
    | `header.blocks.${number}.slides`
    | `blocks.${number}.slides`;
}) {
  const { fields, move, remove, update, append } = useFieldArray({
    control,
    name,
  });

  return (
    <MDBox
      sx={{ overflowX: "scroll" }}
      // prevent cutoff shodow
      m={-1}
      p={1}
      mr={0}
      ml={-3}
    >
      <Stack direction="row">
        {fields.map((item, slideIndex) => (
          <Stack
            key={`${slideIndex}`}
            sx={{
              userSelect: "none",
            }}
            p={2}
            pb={0}
            alignItems="center"
            spacing={1}
          >
            <FixHeightWPImage
              id={item.image_id}
              size="woocommerce_single"
              height={height}
            />

            <Stack direction="row" justifyContent="center" alignItems="center">
              {slideIndex > 0 && (
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  onClick={() => move(slideIndex, slideIndex - 1)}
                >
                  <ArrowBackIosNewIcon />
                </MDButton>
              )}

              <MDButton
                variant="text"
                color="warning"
                iconOnly
                onClick={() => remove(slideIndex)}
              >
                <DeleteIcon />
              </MDButton>

              {slideIndex < fields.length - 1 && (
                <MDButton
                  variant="text"
                  color="dark"
                  iconOnly
                  onClick={() => move(slideIndex, slideIndex + 1)}
                >
                  <ArrowForwardIosIcon />
                </MDButton>
              )}
            </Stack>

            <Autocomplete
              fullWidth
              size="small"
              options={["category", "url"]}
              value={item.type}
              onChange={(e, option) =>
                update(slideIndex, {
                  ...item,
                  type: option as "category" | "url",
                })
              }
              getOptionLabel={(option) =>
                option.charAt(0).toUpperCase() + option.slice(1)
              }
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  label="Type"
                  {...params}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            {item.type === "category" ? (
              <ProductCategoryAutocomplete
                value={item.category_id}
                onChange={(category_id) =>
                  update(slideIndex, {
                    ...item,
                    category_id,
                  })
                }
                label="Category"
              />
            ) : item.type === "url" ? (
              <MDInput
                variant="standard"
                label="Url"
                value={item.url ?? ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  update(slideIndex, {
                    ...item,
                    url: event.target.value,
                  })
                }
                fullWidth
              />
            ) : null}
          </Stack>
        ))}

        <AddSlideButton
          onUploaded={(ids) => {
            for (let id of ids) append({ image_id: id, type: "category" });
          }}
          height={height}
        />
      </Stack>
    </MDBox>
  );
}
