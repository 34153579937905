import Grid from "@mui/material/Grid";
import Box from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

function PhotoViewItem({ src }: { src: string }) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={3}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <PhotoView src={src}>
        <Box overflow="hidden" width="100%" height={300}>
          <Box component="img" src={src} width="100%" />
        </Box>
      </PhotoView>
    </Grid>
  );
}

export default function Projects() {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        rowSpacing={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxWidth="lg"
        m="auto"
      >
        <Grid item xs={12}>
          <MDTypography
            variant="h2"
            fontSize={{ xs: "1.875rem", sm: "2.0625rem", md: "2.25rem" }}
            textAlign="center"
            gutterBottom
          >
            {t("Product Showcase")}
          </MDTypography>

          <MDTypography variant="body1" textAlign="center">
            {t(
              "Explore our portfolio of products developed for diverse business needs and functionalities. Our team of top-tier interface designers and software developers is ready to create unique and efficient application solutions tailored to your requirements."
            )}
          </MDTypography>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" flexDirection="column" mt={5}>
        <Grid container rowSpacing={5} columnSpacing={5}>
          <PhotoProvider>
            <PhotoViewItem src={require("./hotel.png")} />
            <PhotoViewItem src={require("./tour-booking.png")} />
            <PhotoViewItem src={require("./car-rental.png")} />
            <PhotoViewItem src={require("./events.png")} />
            <PhotoViewItem src={require("./shuttle-bus.png")} />
            <PhotoViewItem src={require("./flight-booking.png")} />
            <PhotoViewItem src={require("./cruise-booking.png")} />
            <PhotoViewItem src={require("./news.png")} />
          </PhotoProvider>
        </Grid>
      </Box>
    </>
  );
}
