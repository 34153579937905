import api from "services/core/_api";

type TCheckoutFields = {
  delivery_date: {
    enabled: boolean;
    description: string;
  };
};

export type TCheckoutFieldsRequest = TCheckoutFields;

export type TCheckoutFieldsResponse = TCheckoutFields;

const checkoutFields = {
  get: async (): Promise<TCheckoutFieldsResponse> =>
    (await api.get("admin/checkout_fields")).data,
  post: async (data: TCheckoutFieldsRequest): Promise<{}> =>
    (await api.post("admin/checkout_fields", data)).data,
};

export default checkoutFields;
