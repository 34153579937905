import api from "services/core/_api";

type TOrderNotifications = {
  whatsapp: string | null;
};

export type TOrderNotificationsRequest = TOrderNotifications;

export type TOrderNotificationsResponse = TOrderNotifications;

const orderNotifications = {
  get: async (): Promise<TOrderNotificationsResponse> =>
    (await api.get("admin/order_notifications")).data,
  post: async (data: TOrderNotificationsRequest): Promise<{}> =>
    (await api.post("admin/order_notifications", data)).data,
};

export default orderNotifications;
