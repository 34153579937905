/* eslint-disable no-template-curly-in-string */

const zhHk = {
  translation: {
    // Common
    Pricing: "收費",
    Support: "支援服務",
    Privacy: "私隱政策",
    "Privacy policy": "私隱政策",
    Email: "電郵",
    ":": "：",

    // Home
    "Online store, car rental, food delivery, booking apps, etc.":
      "網店、租車、外賣、預約App等等",
    "Learn More": "了解更多",
    "Mobile App (iOS + Android) & Website":
      "開發手機App (iOS + Android) 及網站",

    "Customer log in": "客戶登入",
    "Pricing / Demo": "收費 / 試用",

    "Develop Mobile Apps and Websites with Zero Upfront Cost":
      "零開發費打造專業手機App和網站",

    "Experience mobile app and website development without any upfront fees. Our service includes unlimited feature updates, custom-tailored to your needs by real developers—not just plugins or templates. Our tech support team consists of real humans providing real-time assistance in Cantonese and English from 8:00 AM to 11:59 PM HKT via WhatsApp and telephone. Pay only a {{from}} to {{to}} fee per transaction, simplifying your expansion with no hidden costs.":
      "提供手機App和網站開發服務，無需開發費用。我們承諾提供不限次數的功能更新，由專業開發人員按您需求量身定制，非單純的插件或模板。提供真人即時廣東話或英語技術支援，非自動機械回答，支援時間為香港時間每日8:00AM至11:59PM。費用只需每單交易支付{{from}}至{{to}}，讓您無後顧之憂，輕鬆擴展業務。",

    "Zero Upfront Costs": "零開發費",
    "Launch your custom e-commerce app and website with us, and you won't pay a dime until your business starts seeing success. We invest our expertise in you because we believe in your vision.":
      "與我們一起推出您的定制網店手機App和網站，在你未成功售出產品前，無需支付任何費用。我們把專業知識投資在您身上，因我們深信您的市場遠見。",
    "Tailored to Your Needs": "免費度身訂造",
    "Forget one-size-fits-all solutions. Your business is unique, and your digital tools should be too. We work closely with you to develop and maintain custom features that drive your business forward.":
      "拋棄罐頭解決方案。您的業務是獨一無二的，您的IT工具也該如此。我們將與您緊密合作，開發能有效推動你業務發展的定制功能。",
    "Close Communication": "緊密溝通",
    "Say goodbye to the frustration of miscommunication with distant IT firms. Our partnership model ensures you have a dedicated, understanding partner in every step of your app's journey.":
      "我們深信緊密溝通是成功合作的關鍵。避開收取月費或一次性開發費而忽視後續服務的傳統IT 公司合作模式，我們採用銷售佣金制，無需額外費用，確保您的需求和業務提升始終被優先考慮。",
    "Continuous Updates, No Extra Charge": "持續更新，無額外收費",
    "The digital market doesn't stand still, and neither should your app. We provide ongoing updates to keep you competitive, all included in our success-based partnership.":
      "市場日新月異，您的網店也應如此。我們提供持續更新，確保您保持競爭力。我們透過銷售佣金制度，免除更新與改良的額外成本，讓您享受真正的共贏合作。",
    Tel: "電話",

    "Simple and User-Friendly.": "輕鬆上手，操作無難度。",
    "User-friendly, beautifully designed, and extremely fast, with all the necessary features included.":
      "極致用戶體驗，設計精美，速度極快，功能一應俱全。",
    "Accept payments without limitations.": "接受國際及本地支付，無地域限制。",
    "No need to limit your payment options - accept credit and debit cards, PayPal, bank transfers, PayMe, WeChat Pay, Alipay and more to make transactions a breeze.":
      "無需限制您的支付選項 - 接受信用卡、PayPal、銀行轉賬、PayMe、WeChat Pay、Alipay等，讓交易暢通無阻。",
    "Unlock store potential with in-depth insights.":
      "深度數據分析，推動業績增長。",
    "Track your store's performance with detailed reporting on sales, revenue and customer behavior. Boost conversions and customer loyalty.":
      "從銷售數據到顧客動態，全面報告助您精準定位，提升轉化率及顧客忠誠度，建立牢不可破的銷售關係網。",

    "Two simple plans, each with a ": "兩種簡單方案，每個都有 ",
    "30-day free trial.": "30 天免費試用。",
    "No credit card required.": "無需信用卡。",

    "Partnership First - No Fees Until Your Success":
      "月費或佣金方案，確保我們專注於您的成功。",
    commission: "佣金",
    "No Monthly Fees. When you make a sale, we charge only a {{from}} - {{to}} commission.":
      "無月費，每張成功的訂單只收取{{from}} - {{to}}佣金。",
    "Commission-Based Plan": "佣金制計劃",
    "Monthly Plan": "月費計劃",
    mo: "月",
    "Limited Time Offer": "限時優惠",
    "3 of 30 left": "尚餘3個，總數30個",
    "* No Upfront Cost, No maintenance fee, No hidden fees.":
      "* 無開發費，無維護費，無隱藏收費。",
    "Develop iOS & Android mobile apps": "開發 iOS 和 Android 手機 App",
    "Develop websites": "開發網站",
    "1:1 onboarding tour with our team": "專業團隊 1 對 1 指導",
    "Pay-as-you-go model": "用多少付多少",
    "First-in-line priority support": "即時技術支援服務",
    "Develop any new features you want (no additional charges)":
      "開發任何您想要的新功能（無需額外收費）",
    "Try it for free": "免費試用",

    "No Upfront Fees.": "無開發費。",
    "We offer mobile app and website development services without any upfront costs.":
      "我們提供手機App和網站開發服務，無需支付任何開發費用。",
    "Develop any new features, no additional charges.":
      "開發任何新功能，無需額外收費。",
    "Enjoy unlimited, custom feature updates crafted by professional developers, not just plugins or templates.":
      "不限次數的功能定制更新，全部由專業開發者按您需求量身定制，非單純的插件或模板。",
    "Risk-free, cancel anytime, no long-term lock-in.":
      "無風險，隨時取消，沒有長期綁約。",
    "With {{name}}, cancellation is entirely self-serve, no questions asked, no retention specialists trying to talk you out of it. Cancel any time, no long-term contracts to lock you in. Simple, straightforward, and fair, just as it should be.":
      "使用 {{name}}，取消續約完全自助，無需解釋，也不會有專員試圖挽留你。隨時可以取消，沒有長期合約綁住你。一切都簡單、直接、公平，正如它應該是的那樣。",
    "Real human tech support.": "真人即時技術支援。",
    "Real human tech support is available in Cantonese and English from 8:00 AM to 11:59 PM HKT via WhatsApp and telephone, ensuring you get the help you need when you need it.":
      "提供真人即時技術支援，支援語言包括廣東話和英語，服務時間為香港時間每日8:00AM至11:59PM，透過WhatsApp和電話。",

    "Product Showcase": "產品演示",
    "Explore our portfolio of products developed for diverse business needs and functionalities. Our team of top-tier interface designers and software developers is ready to create unique and efficient application solutions tailored to your requirements.":
      "探索我們為不同業務需求和功能定制開發的產品組合。我們擁有頂尖的界面設計師和軟件開發人員團隊，隨時準備為您打造獨特且高效的應用解決方案。",

    "Trusted by Industry Leaders": "深受業界領袖信賴",
    "Our expertise recognized across the spectrum":
      "我們的專業團隊，品牌經驗豐富，客戶遍佈各行各業。",

    FAQs: "常見問題",
    "What are the fees?": "費用是多少？",
    "Unlike other platforms, {{name}} doesn’t charge you a monthly fee. Instead, our fees are deducted as a small percentage of every sale, so we only make money when you do.":
      "我們只會在您有收入時才會向你收費，收費方式是從每次銷售中扣除一小部分百分比。與其他平台不同，{{name}} 不會向您收取月費及開發費。",
    "Where do my orders go?": "我的訂單會去哪裡？",
    "We've created an easy-to-use order management system for you. Simply log into your admin dashboard and you'll have access to manage your orders, track your earnings, add new products, and more!":
      "我們為您創建了一個易於使用的訂單管理系統。只需登入您的管理員儀表板，您就可以管理訂單、追蹤收入、新增產品等等！",
    "How do I receive payments?": "我怎樣收到付款？",
    "You have complete flexibility in choosing your preferred method of payment. We support a wide range of options from credit cards to bank transfers, as well as popular e-wallets like PayMe, WeChat Pay and Alipay.":
      "您可以完全靈活地選擇您偏好的付款方式。我們提供從信用卡到銀行轉賬等多種選擇，以及PayMe、WeChat Pay和Alipay等受歡迎的電子錢包。",
    "What countries do you support?": "你們支援哪些國家？",
    "{{name}} is available for use by anyone, anywhere in the world!":
      "無論身在世界何處，任何人都能享用 {{name}} 的全球服務！",
    "Where can I get in touch for corporate or custom solutions?":
      "如需企業或定制解決方案，我應該如何聯繫？",
    "If you have specific needs or want tailored solutions with our platform, please ":
      "如果您有特定需求或希望我們的平台提供量身定制的解決方案，請",
    "contact us": "聯繫我們",
    ".": "。",
    "For any general inquiries about our product, please ":
      "如有任何關於我們產品的一般性查詢，請",
    "send us an email": "發送電子郵件給我們",
    "We're here to help.": "隨時為您提供援助",
    "Always humans, never bots. For pre-sales questions, existing customers who need a hand, or other inquiries, ":
      "永遠是真人，從不使用機械人。不論是售前問題、現有客戶需要協助，或其他查詢，",
    " and ": "，",
    "we’ll get back to you within an hour.": "我們將在一小時內回覆您。",

    "{{name}} is designed, built, and backed by {{companyName}}. Enjoy the rest of your {{weekday}}!":
      "{{name}} 由 {{companyName}} 設計、打造及支援。祝您{{weekday}}餘下的時間愉快！",

    // Support
    "Get help from the {{name}} support team": "支援服務",
    "Friendly folks, standing by.": "專業的團隊，隨時為您服務。",
    "we’ll get back to you in about {{responseTime}} minutes":
      "我們會在大約 {{responseTime}} 分鐘內回覆您",
    "Send us a message to get help": "需要協助？立即發訊息給我們！",
    "What do you need help with?": "您需要什麼幫助？",
    "This helps make sure you get the right answer fast.":
      "這有助確保您能迅速獲得正確的答案。",
    "Please select one…": "請選擇一個…",
    REQUIRED: "必填",
    "I can’t access my account": "我無法訪問我的帳戶",
    "I have a question before I sign up": "我在註冊前有問題",
    "I want to request a feature": "我想開發一個新功能",
    "I have a billing question": "我有一個賬單問題",
    "I’m not receiving emails": "我沒有收到電子郵件",
    "I’m confused about how something works": "我對某事如何運作感到困惑",
    "I think something is broken": "我認為某些東西壞了",
    Other: "其他",
    "What’s your question, comment, or issue?": "您的問題、評論或疑慮是什麼？",
    "Share all the details. The more we know, the better we can help you.":
      "請分享所有細節。我們了解得越多，就越能為你提供更好的幫助。",

    "Send us a file, screenshot, or document": "發送文件、截圖或文檔給我們",
    "Hold the shift key to select multiple files.":
      "按住shift鍵以選擇多個文件。",
    "Attachments must be 10MB or less": "附件大小必須為10MB或以下",
    "What’s your email address?": "您的電郵地址是什麼？",
    "Enter your email address": "輸入您的電郵地址",
    "This is where we’ll get back to you. Double check that it’s right.":
      "這是我們回覆您的地方。請再次確認地址無誤。",
    "What’s your account URL?": "您的帳戶URL是什麼？",
    "Enter your account URL…": "輸入您的帳戶URL…",
    "Example: {{value}}": "例如：{{value}}",
    "Our current response time is about {{responseTime}} minutes.":
      "我們目前的回應時間大約為 {{responseTime}} 分鐘。",
    "Send support request": "發送",
    "We’re here from {{weekdaysFrom}} to {{weekdaysTo}} GMT Monday through Friday. On weekends, we’re here from {{weekendsFrom}} to {{weekendsTo}} GMT.":
      "我們的服務時間為週一至週五GMT {{weekdaysFrom}} 至 {{weekdaysTo}}。週末的服務時間為GMT {{weekendsFrom}} 至 {{weekendsTo}}。",

    "We’ll get back to you soon.": "我們會盡快回覆您。",
    "Help is on the way!": "支援服務",

    // Sign in
    Password: "密碼",
    "Sign in to": "登入",
    "Forgot your password?": "忘記密碼？",
    "Sign In": "登入",

    // Request Demo Dialog
    "Schedule Your Demo": "預約免費試用",
    "Full Name": "全名",
    "Phone Number": "電話號碼",
    "Your Message (Optional)": "您的訊息（可選）",
  },
};

export default zhHk;
