import Stack from "@mui/material/Stack";
import MDTypography from "components/MDTypography";
import Box from "@mui/material/Box";
import { useState } from "react";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import RequestDemoDialog from "components/RequestDemoDialog/RequestDemoDialog";
import config from "config";
import WhatsAppButton from "components/WhatsAppButton";
import EmailButton from "components/EmailButton";
import Typography from "@mui/material/Typography";
import MDBox from "components/MDBox";
import DoneIcon from "@mui/icons-material/Done";

export default function PriceCard({
  button,
  gold,
  hideLgBorderRight,
}: {
  button?: boolean;
  gold?: boolean;
  hideLgBorderRight?: boolean;
}) {
  const { t } = useTranslation();

  const color = gold ? "rgb(255, 190, 99)" : "#fff";

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Stack
        direction="column"
        sx={{
          backgroundColor: "rgba(20, 23, 39, 0.8)",
          backgroundImage: gold
            ? `url(${require("./bonus-bg.webp")})`
            : undefined,
          backgroundSize: "cover",
          boxShadow: gold
            ? "rgba(255, 190, 99, 0.15) 0px 8px 26px -4px, rgba(255, 190, 99, 0.06) 0px 8px 9px -5px"
            : "rgba(255, 255, 255, 0.15) 0px 8px 26px -4px, rgba(255, 255, 255, 0.06) 0px 8px 9px -5px",
          borderColor: color,
          borderStyle: "solid",
          borderWidth: 3,
          borderRadius: "1rem",
          borderRightWidth: {
            sx: 3,
            lg: hideLgBorderRight ? 0 : undefined,
          },
          borderTopRightRadius: {
            sx: "1rem",
            lg: hideLgBorderRight ? 0 : undefined,
          },
          borderBottomRightRadius: {
            sx: "1rem",
            lg: hideLgBorderRight ? 0 : undefined,
          },
          p: 2,
        }}
        width="100%"
        borderRadius={1}
        alignItems="flex-start"
      >
        <Typography variant="h4" color={color} gutterBottom>
          {gold ? t("Commission-Based Plan") : t("Monthly Plan")}
        </Typography>

        {[
          {
            title: t("Develop iOS & Android mobile apps"),
          },
          {
            title: t("Develop websites"),
          },
          {
            title: t("1:1 onboarding tour with our team"),
          },
          {
            title: t("Pay-as-you-go model"),
          },
          {
            title: t("First-in-line priority support"),
            goldOnly: true,
          },
          {
            title: t(
              "Develop any new features you want (no additional charges)"
            ),
            goldOnly: true,
          },
        ].map(
          ({ title, goldOnly }, i) =>
            (gold || !goldOnly) && (
              <MDBox key={title} display="flex">
                <Typography variant="body2" color={goldOnly ? color : "#fff"}>
                  <DoneIcon />
                </Typography>
                <Typography
                  variant="body2"
                  color={goldOnly ? color : "#fff"}
                  fontWeight={goldOnly ? "bold" : "500"}
                  fontSize="0.875rem"
                  pl={0.75}
                >
                  {title}
                </Typography>
              </MDBox>
            )
        )}

        <MDTypography variant="h6" sx={{ color }}>
          <Box
            component="span"
            fontSize={"1.25rem"}
            fontWeight="600"
            sx={{ textDecoration: "line-through" }}
          >
            {`$3,300`}
          </Box>{" "}
          <Box
            component="span"
            fontSize={gold ? "3rem" : "2.125rem"}
            fontWeight="700"
          >
            {gold ? "$0" : `$1,980`}
          </Box>
          <Typography
            display="inline"
            component="small"
            variant="h5"
            color="inherit"
          >
            /{t("mo")}
          </Typography>
        </MDTypography>

        {gold && (
          <Typography variant="h6" color={color} gutterBottom>
            {t(
              "No Monthly Fees. When you make a sale, we charge only a {{from}} - {{to}} commission.",
              config.price
            )}
          </Typography>
        )}

        <MDTypography variant="button" sx={{ color }}>
          {t("* No Upfront Cost, No maintenance fee, No hidden fees.")}
        </MDTypography>

        {button && (
          <MDButton
            type="submit"
            variant="contained"
            color={gold ? "warning" : "light"}
            sx={{
              backgroundColor: color,
              color: "rgb(2, 6, 23)",
              ":hover": {
                backgroundColor: color,
                color: "rgb(2, 6, 23)",
              },
              mt: 2,
              mb: 2,
            }}
            size="large"
            onClick={() => {
              if (gold) {
                // @ts-ignore: Event snippet for eShopRocket Pricing Section Action Button Clicked conversion page
                gtag("event", "conversion", {
                  send_to: "AW-965382839/SNNpCNeqi6gZELelqswD",
                });
              } else {
                // @ts-ignore: Event snippet for eShopRocket Pricing Section OTP Action Button Clicked conversion page
                gtag("event", "conversion", {
                  send_to: "AW-965382839/79dcCJCAjasZELelqswD",
                });
              }

              setOpen(true);
            }}
          >
            {t("Try it for free")}
          </MDButton>
        )}

        <WhatsAppButton sx={{ color, minHeight: 30 }} color="warning" />

        <EmailButton sx={{ color, minHeight: 30 }} color="warning" />
      </Stack>

      <RequestDemoDialog open={open} onClose={handleClose} gold={gold} />
    </>
  );
}
