import MDBox from "components/MDBox";
import LoadingIcon from "components/LoadingIcon";

import DashboardSection from "../DashboardSection";

import CheckoutFieldsForm from "./CheckoutFieldsForm";
import useApi from "libs/useApi";
import checkoutFields from "services/core/checkoutFields";

export default function CheckoutFields() {
  const { data, refresh } = useApi(checkoutFields.get);

  return (
    <DashboardSection
      title="Checkout fields"
      description="Add fields to your Checkout page"
    >
      {data ? (
        <CheckoutFieldsForm data={data} onSubmit={refresh} />
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <LoadingIcon />
        </MDBox>
      )}
    </DashboardSection>
  );
}
