import Box from "@mui/material/Box";

import LoadingIcon from "components/LoadingIcon";

export default function Loading() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <LoadingIcon />
    </Box>
  );
}
