import Card from "@mui/material/Card";

export default function PageBody({ children }: { children: React.ReactNode }) {
  return (
    <Card
      sx={{
        mx: { xs: 2, lg: 3 },
        mt: -8,
        mb: 4,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "saturate(200%) blur(30px)",
        boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%)",
      }}
    >
      {children}
    </Card>
  );
}
