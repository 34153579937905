import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import zhHantHK from "./zh-Hant-HK";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      "zh-Hant-HK": zhHantHK,
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    keySeparator: ">",
    nsSeparator: "|",
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",

        // remove comment out if go en market. If only for hong kong, don't use browser's language.
        // "navigator",

        // check <html lang="zh-Hant-HK">, change to <html lang="en"> if go en market
        "htmlTag",
      ],
      convertDetectedLanguage: (lng) => {
        if (lng.includes("zh")) return "zh-Hant-HK";
        return "en";
      },
    },
  });
