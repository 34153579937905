import api from "services/core/_api";

export type TProductsCategory = {
  id: number;
  name: string;
  slug: string;
  parent: number;
  description: string;
  display: string;
  // image: [];
  menu_order: number;
  count: number;
};

export type TProductsCategories = TProductsCategory[];

const productCategories = {
  get: async (params?: {
    per_page?: number;
    page?: number;
    hide_empty?: boolean;
  }): Promise<TProductsCategories> =>
    (await api.get(`products/categories`, { params })).data,
};

export default productCategories;
