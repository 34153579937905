import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { Link as RouteLink } from "react-router-dom";

import MDTypography from "components/MDTypography";
import config from "config";

import Faq from "./Faq";

export default function Faqs() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      rowSpacing={5}
      display="flex"
      justifyContent="center"
      maxWidth="lg"
      m="auto"
      mt={10}
    >
      <Grid item xs={12}>
        <MDTypography variant="h2" textAlign="center" gutterBottom>
          {t("FAQs")}
        </MDTypography>
      </Grid>

      <Grid item xs={12}>
        <Faq title={t("What are the fees?")}>
          {t(
            "Unlike other platforms, {{name}} doesn’t charge you a monthly fee. Instead, our fees are deducted as a small percentage of every sale, so we only make money when you do.",
            { name: config.name }
          )}
        </Faq>

        <Faq title={t("Where do my orders go?")}>
          {t(
            "We've created an easy-to-use order management system for you. Simply log into your admin dashboard and you'll have access to manage your orders, track your earnings, add new products, and more!"
          )}
        </Faq>

        <Faq title={t("How do I receive payments?")}>
          {t(
            "You have complete flexibility in choosing your preferred method of payment. We support a wide range of options from credit cards to bank transfers, as well as popular e-wallets like PayMe, WeChat Pay and Alipay."
          )}
        </Faq>

        <Faq title={t("What countries do you support?")}>
          {t(
            "{{name}} is available for use by anyone, anywhere in the world!",
            { name: config.name }
          )}
        </Faq>

        <Faq
          title={t(
            "Where can I get in touch for corporate or custom solutions?"
          )}
        >
          {t(
            "If you have specific needs or want tailored solutions with our platform, please "
          )}
          <Link component={RouteLink} to="/support" fontWeight="bold">
            {t("contact us")}
          </Link>
          {t(".")}
          <br />
          <br />
          {t("For any general inquiries about our product, please ")}
          <Link href={`mailto:${config.supportEmail}`} fontWeight="bold">
            {t("send us an email")}
          </Link>
          {t(".")}
        </Faq>
      </Grid>
    </Grid>
  );
}
