import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import LoadingIcon from "components/LoadingIcon";

import config from "config";

import { getSite } from "libs/sites";

export default function Orders() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const requested_site = searchParams.get("site");
  const requestedSite = requested_site
    ? decodeURIComponent(requested_site)
    : null;

  useEffect(() => {
    if (!requestedSite) {
      navigate("/u", { replace: true });
      return;
    }

    (async () => {
      const site = await getSite();

      if (!site || (requestedSite && site.subdomain !== requestedSite)) {
        navigate("/signin", {
          replace: true,
          state: { requestedLocation: location },
        });
        return;
      }

      window.location.href = `${config.wpProtocol}//${site.subdomain}.${config.wpDomain}/wp-admin/edit.php?post_type=shop_order`;
    })();
  }, [location, navigate, requestedSite]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <LoadingIcon />
    </Box>
  );
}
