import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import { getProductCategories } from "libs/cachedProductCategories";
import { useEffect, useMemo, useState } from "react";
import { TProductsCategories } from "services/core/productCategories";

export default function ProductCategoriesAutocomplete({
  value: propsValue,
  onChange,
  label,
}: {
  value?: number;
  onChange: (value?: number) => void;
  label?: string;
}) {
  const [data, setData] = useState<TProductsCategories | null>(null);

  useEffect(() => {
    (async () => {
      setData(await getProductCategories());
    })();
  }, []);

  const value = useMemo(
    () => data?.find(({ id }) => id === propsValue),
    [propsValue, data]
  );

  if (!data) return null;

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={data}
      value={value}
      onChange={(e, value) => onChange(value?.id)}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <MDInput
          variant="standard"
          label={label}
          {...params}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
