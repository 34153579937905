import Box from "@mui/material/Box";
import { Control, useFieldArray } from "react-hook-form";

import MDButton from "components/MDButton";
import { TShippingZonesFormValues } from "./types";
import MDTypography from "components/MDTypography";
import { TFlexibleShippingSingle } from "services/wc/shippingZoneMethods";
import ShippingZoneMethod from "./ShippingZoneMethod";

export default function ShippingZoneMethods({
  control,
  shippingZoneIndex,
}: {
  control: Control<TShippingZonesFormValues>;
  shippingZoneIndex: number;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `shippingZones.${shippingZoneIndex}.methods`,
  });

  // fields will not update... if useMemo
  const isShowRulesInstruction =
    fields
      .filter(
        (o): o is TFlexibleShippingSingle & { id: string } =>
          o.method_id === "flexible_shipping_single"
      )
      .filter(
        ({ settings }) =>
          settings.method_rules.value[0]?.conditions[0]?.min ||
          settings.method_rules.value[0]?.conditions[0]?.max
      ).length > 1;

  return (
    <Box display="flex" flexDirection="column">
      {isShowRulesInstruction && (
        <MDTypography variant="caption" mb={1}>
          Please mind that the ranges you define must not overlap each other and
          make sure there are no gaps between them.
          <br />
          <strong>Example:</strong> The first range covers $0-$100, the next one
          should start from <strong>$100.01</strong>, not from{" "}
          <strong>$101</strong>, etc.
        </MDTypography>
      )}

      {fields.map((method, i) => (
        <ShippingZoneMethod
          key={method.id}
          control={control}
          shippingZoneIndex={shippingZoneIndex}
          shippingZoneMethodIndex={i}
          onRemove={() => remove(i)}
        />
      ))}

      <MDButton
        onClick={() =>
          append({
            method_id: "flexible_shipping_single",
            settings: {
              method_title: { value: "" },
              method_description: { value: "" },
              method_rules: {
                value: [
                  {
                    conditions: [
                      {
                        condition_id: "value",
                        min: "",
                        max: "",
                      },
                    ],
                    cost_per_order: "",
                    additional_costs: [],
                    special_action: "",
                  },
                ],
              },
            },
          })
        }
        variant="text"
        color="dark"
        size="small"
      >
        + Add delivery method
      </MDButton>
    </Box>
  );
}
