import api from "services/core/_api";

type TSite = {
  enabled: boolean;
};

export type TSiteRequest = TSite;

export type TSiteResponse = TSite;

const site = {
  get: async (): Promise<TSiteResponse> => (await api.get("admin/site")).data,
  post: async (data: TSiteRequest): Promise<{}> =>
    (await api.post("admin/site", data)).data,
};

export default site;
