import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function IntroCard({
  icon,
  title,
  description,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
}) {
  return (
    <Box display="flex" flexDirection="column" lineHeight={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3rem"
        height="3rem"
        borderRadius="xl"
        variant="gradient"
        color="white"
        bgColor="dark"
        coloredShadow="dark"
      >
        {icon}
      </MDBox>
      <MDTypography
        display="block"
        variant="5"
        fontWeight="bold"
        mt={2.5}
        mb={1.5}
      >
        {title}
      </MDTypography>
      <MDTypography display="block" variant="body2" color="dark">
        {description}
      </MDTypography>
    </Box>
  );
}
