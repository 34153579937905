// @ts-ignore
import YoutubeBackground from "react-youtube-background";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

export default function PageHeader({
  minHeight,
  bgImage,
  children,
  alpha,
  videoId,
}: {
  minHeight: string;
  bgImage: string;
  children: React.ReactNode;
  alpha: number;
  videoId?: string;
}) {
  const [playing, setPlaying] = useState(false);

  const playRef = useRef();

  useEffect(() => {
    // auto play when switching back to Safari from background
    // @ts-ignore
    const timer = setInterval(() => playRef.current?.playVideo(), 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (!videoId) {
    return (
      <MDBox
        minHeight={minHeight}
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, alpha),
              rgba(gradients.dark.state, alpha)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        {children}
      </MDBox>
    );
  }

  return (
    <YoutubeBackground
      videoId={videoId}
      onPlay={() => setPlaying(true)}
      // @ts-ignore
      onReady={(e) => {
        playRef.current = e.target;
      }}
    >
      <div
        style={{
          backgroundImage: playing ? "none" : `url("${bgImage}")`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
            top: 0,
            background: `rgba(0,0,0,.65)`,
          }}
        />
        <Box
          minHeight={minHeight}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          position="relative"
        >
          {children}
        </Box>
      </div>
    </YoutubeBackground>
  );
}
