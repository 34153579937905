import Grid from "@mui/material/Grid";
import Box from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";

const ImageGrid = ({ src }: { src: string }) => (
  <Grid
    item
    xs={6}
    sm={4}
    md={4}
    lg={2}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Box
      component="img"
      sx={{
        width: {
          xs: "95%",
          sm: "90%",
          md: "70%",
          lg: "90%",
        },
      }}
      src={src}
    />
  </Grid>
);

export default function Customers() {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        rowSpacing={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxWidth="lg"
        m="auto"
      >
        <Grid item xs={12}>
          <MDTypography
            variant="h2"
            fontSize={{ xs: "1.875rem", sm: "2.0625rem", md: "2.25rem" }}
            textAlign="center"
            gutterBottom
          >
            {t("Trusted by Industry Leaders")}
          </MDTypography>

          <MDTypography variant="body1" textAlign="center">
            {t("Our expertise recognized across the spectrum")}
          </MDTypography>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" flexDirection="column" mt={5}>
        <Grid container rowSpacing={5} columnSpacing={5}>
          <ImageGrid src={require("./lanecrawford.png")} />
          <ImageGrid src={require("./owd.png")} />
          <ImageGrid src={require("./oceanpark.png")} />
          <ImageGrid src={require("./foodwise.png")} />
          <ImageGrid src={require("./gammon.png")} />
          <ImageGrid src={require("./bps.png")} />
        </Grid>
      </Box>
    </>
  );
}
