import { ReactEventHandler, useState } from "react";
import MDBox from "./MDBox";

export default function ShadowImage({
  onLoad,
  width,
  height,
  src,
  opacity,
}: {
  onLoad?: ReactEventHandler;
  width?: number;
  height?: number;
  src: string;
  opacity?: number;
}) {
  const [shadowWidth, setShadowWidth] = useState<number>();
  const [shadowHeight, setShadowHeight] = useState<number>();

  return (
    <MDBox
      position="relative"
      width={shadowWidth}
      height={shadowHeight}
      opacity={opacity}
    >
      <MDBox
        position="absolute"
        left={0}
        top={0}
        zIndex={1}
        borderRadius="lg"
        shadow="md"
        display="flex"
        sx={{ backgroundColor: "#fff" }}
      >
        <MDBox
          borderRadius="lg"
          component="img"
          src={src}
          width={width}
          height={height}
          onLoad={(e) => {
            const { width, height } = e.target as unknown as {
              width: number;
              height: number;
            };

            setShadowHeight(height);
            setShadowWidth(width);
            onLoad?.(e);
          }}
        />
      </MDBox>
      <MDBox
        borderRadius="lg"
        shadow="md"
        position="absolute"
        left={0}
        top={0}
        width={shadowWidth}
        height={shadowHeight}
        sx={{
          backgroundImage: `url(${src})`,
          transform: "scale(0.94)",
          filter: "blur(12px)",
          backgroundSize: "cover",
        }}
      />
    </MDBox>
  );
}
