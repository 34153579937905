import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import SaveButton from "routes/u/Dashboard/SaveButton";
import { TBacsRequest } from "services/core/paymentGateways";

export default function Bacs({
  values,
  onSubmit,
}: {
  values: TBacsRequest;
  onSubmit: (values: TBacsRequest) => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      title: values.title,
      description:
        values.description ||
        "Please include your order ID in the reference. Payment must be completed within 30 minutes.",
    },
  });

  const handleSave = handleSubmit(
    async ({ title, description }: { title: string; description: string }) => {
      const newValues = { ...values, title, description };

      await onSubmit(newValues);
    }
  );

  useEffect(() => {
    if (isSubmitSuccessful)
      reset({
        title: values.title,
        description:
          values.description ||
          "Please include your order ID in the reference. Payment must be completed within 30 minutes.",
      });
  }, [isSubmitSuccessful, reset, values.description, values.title]);

  return (
    <Stack mt={2} spacing={2}>
      <TextField
        margin="dense"
        label="Bank information"
        {...register("title", {
          required: "This field is required",
        })}
        error={!!errors.title}
        helperText={errors.title?.message}
        fullWidth
      />
      <TextField
        margin="dense"
        label="Account name, account number, instructions, etc."
        {...register("description", {
          required: "This field is required",
        })}
        error={!!errors.description}
        helperText={errors.description?.message}
        fullWidth
        multiline
      />

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </Stack>
  );
}
