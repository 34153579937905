import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import config from "config";
import supabase from "libs/supabase";
import Loading from "routes/Loading/Loading";
import { getSites } from "libs/sites";
import { getSession } from "libs/session";

export default function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const session = await getSession();

      if (!session) {
        navigate("/u", { replace: true });
        return;
      }

      const sites = await getSites();

      try {
        if (sites)
          await Promise.all(
            sites.map((site) =>
              fetch(
                `${config.wpProtocol}//${site.subdomain}.${config.wpDomain}/wp-json/${config.server.shopApiNamespace}/admin/signout`,
                {
                  method: "post",
                  headers: {
                    "Admin-Token": session.access_token,
                  },
                }
              )
            )
          );
      } catch {}

      await supabase.auth.signOut();

      navigate("/u", { replace: true });
    })();
  }, [navigate]);

  return <Loading />;
}
