import { useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TShippingZonesFormValues } from "./types";
import { Control, Controller } from "react-hook-form";
import useAsync from "libs/useAsync";
import { getDataContinents } from "libs/cachedDataContinents";
import LoadingIcon from "components/LoadingIcon";
import Box from "@mui/material/Box";
import { getSettingOptionsGeneralWoocommerceSpecificShipToCountries } from "libs/cachedSettingOptionsGeneralWoocommerceSpecificShipToCountries";

export function ShippingZoneLocations({
  control,
  shippingZoneIndex,
}: {
  control: Control<TShippingZonesFormValues>;
  shippingZoneIndex: number;
}) {
  const { value: continents } = useAsync(getDataContinents, []);

  const { value: specificShipToCountries } = useAsync(
    getSettingOptionsGeneralWoocommerceSpecificShipToCountries,
    []
  );

  const options = useMemo(() => {
    if (!continents) return;
    if (!specificShipToCountries) return;

    // get isAll
    const isSpecific =
      Array.isArray(specificShipToCountries.value) &&
      specificShipToCountries.value.length > 0;

    // get options
    const options = [];

    continents.sort((a, b) => a.name.localeCompare(b.name));

    for (let continent of continents) {
      options.push({
        group: continent.name,
        label: continent.name,
        value: { code: continent.code, type: "continent" },
      });

      continent.countries.sort((a, b) =>
        specificShipToCountries.options[a.code]?.localeCompare(
          specificShipToCountries.options[b.code]
        )
      );

      for (let country of continent.countries) {
        if (isSpecific && !specificShipToCountries.value.includes(country.code))
          continue;
        const countryName = specificShipToCountries.options[country.code];
        if (!countryName) continue;

        options.push({
          group: countryName,
          label: countryName,
          value: { code: country.code, type: "country" },
        });

        country.states.sort((a, b) => a.name.localeCompare(b.name));

        for (let state of country.states) {
          options.push({
            group: countryName,
            label: `${state.name}, ${countryName}`,
            value: {
              code: `${country.code}:${state.code}`,
              type: "state",
            },
          });
        }
      }
    }

    return options;
  }, [continents, specificShipToCountries]);

  return options ? (
    <Controller
      control={control}
      name={`shippingZones.${shippingZoneIndex}.locations`}
      render={({ field, formState: { isSubmitting } }) => (
        <Autocomplete
          sx={{ "& label": { lineHeight: "unset" } }}
          multiple
          options={options}
          getOptionKey={(option) => `${option.value.type}:${option.value.code}`}
          getOptionLabel={(option) => `${option.label}`}
          groupBy={(option) => option.group}
          value={options.filter(({ value }) =>
            field.value?.find(
              ({ code, type }) => value.code === code && value.type === type
            )
          )}
          onChange={(e, value) =>
            field.onChange(value.map(({ value }) => value))
          }
          onBlur={field.onBlur}
          fullWidth
          disabled={isSubmitting}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Select regions within this zone"
              placeholder="Regions"
            />
          )}
        />
      )}
    />
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center">
      <LoadingIcon />
    </Box>
  );
}
