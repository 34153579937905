import { useCallback, useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import StripeDialog from "./StripeDialog";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import LoadingIcon from "components/LoadingIcon";
import stripe from "services/core/stripe";

export default function Stripe() {
  const ref = useRef<HTMLDivElement>();

  const [finishSetupDialogOpen, setFinishSetupDialogOpen] = useState(false);
  const [restartSetupDialogOpen, setRestartSetupDialogOpen] = useState(false);

  const [finished, setFinished] = useState(false);

  const [loading, setLoading] = useState(false);

  const [checkLoading, setCheckLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const handleSubmit = useCallback(async (restart: boolean = false) => {
    setLoading(true);

    // send
    const json = await stripe.accountUrl(restart);

    window.location.href = json.url;

    await new Promise((r) => setTimeout(r, 5000));

    setLoading(false);
  }, []);

  useEffect(() => {
    if (searchParams.get("stripe_refresh") === "true") {
      ref.current?.scrollIntoView();
      setFinishSetupDialogOpen(true);
      handleSubmit(false);
    }

    if (searchParams.get("stripe_return") === "true") {
      ref.current?.scrollIntoView();
    }
  }, [searchParams, handleSubmit]);

  useEffect(() => {
    (async () => {
      setCheckLoading(true);

      // send
      const json = await stripe.check();

      setFinished(json.finished === true);

      setCheckLoading(false);
    })();
  }, []);

  return (
    <>
      <Box ref={ref} sx={{ scrollMarginTop: 170 }} />

      <Stack direction="row" spacing={1} alignItems="center">
        {!finished &&
          (checkLoading ? (
            <LoadingIcon size={20} />
          ) : (
            <MDButton
              onClick={() => setFinishSetupDialogOpen(true)}
              variant="gradient"
              color="dark"
              size="small"
            >
              Finish setup
            </MDButton>
          ))}
        <MDButton
          onClick={() => setRestartSetupDialogOpen(true)}
          variant="outlined"
          color="dark"
          size="small"
        >
          Restart
        </MDButton>
      </Stack>

      <StripeDialog
        open={finishSetupDialogOpen}
        onClose={() => setFinishSetupDialogOpen(false)}
        onSubmit={() => handleSubmit(false)}
        loading={loading}
        title="Set up payments with Stripe"
        description={`We use Stripe for secured payments.\nWe support individuals and businesses.\nA registered entity is not required.`}
      />
      <StripeDialog
        open={restartSetupDialogOpen}
        onClose={() => setRestartSetupDialogOpen(false)}
        onSubmit={() => handleSubmit(true)}
        loading={loading}
        title="Restart verification?"
        description={`You will be brought back to the start of the verification process.\nYour current progress will be lost.`}
      />
    </>
  );
}
