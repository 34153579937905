import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import { SxProps, Theme } from "@mui/system";
import React from "react";

import MasterCard from "components/MasterCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import analytics from "./analytics.jpg";
import analytics_1 from "./analytics_1.jpg";
import analytics_2 from "./analytics_2.jpg";
import analytics_3 from "./analytics_3.jpg";
import simple from "./simple.png";
import simple_1 from "./simple_1.png";
import simple_2 from "./simple_2.png";
import simple_3 from "./simple_3.png";

import { ReactComponent as PaypalIcon } from "./paypal.svg";
import { ReactComponent as VisaIcon } from "./visa.svg";
import { ReactComponent as AmericanExpressIcon } from "./american_express.svg";

import { keyframes } from "@mui/system";

import { useTranslation } from "react-i18next";

const Img = ({
  src,
  left,
  top,
  width,
  sx,
  shadow = false,
}: {
  src: string;
  left: string | number;
  top: string | number;
  width: string | number;
  sx: SxProps<Theme>;
  shadow?: boolean;
}) => (
  <MDBox
    component="img"
    position="absolute"
    borderRadius={shadow ? "lg" : undefined}
    shadow={shadow ? "md" : undefined}
    src={src}
    left={left}
    top={top}
    width={width}
    sx={sx}
  />
);

const Svg = ({
  left,
  top,
  component,
  sx,
}: {
  left: string | number;
  top: string | number;
  component: React.ElementType;
  sx: SxProps<Theme>;
}) => (
  <MDBox
    position="absolute"
    borderRadius="lg"
    shadow="md"
    bgColor="white"
    variant="gradient"
    display="flex"
    zIndex={100}
    left={left}
    top={top}
    p={1}
    sx={sx}
  >
    <SvgIcon component={component} inheritViewBox fontSize="large" />
  </MDBox>
);

const Floatingx = keyframes`
  0% {
    transform: translate(50px,0px);
  }
  65% {
    transform: translate(0,0);
  }
  100% {
    transform: translate(50px,0px);
  }
`;

const Floatingy = keyframes`
  0% {
    transform: translate(0,0);
  }
  65% {
    transform: translate(50px,0);
  }
  100% {
    transform: translate(0,0);
  }
`;

export default function Features() {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems="center">
        <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 } }} mt={8}>
          <MDTypography variant="h3">
            {t("Simple and User-Friendly.")}
          </MDTypography>
          <MDTypography variant="body1" color="text" mb={3}>
            {t(
              "User-friendly, beautifully designed, and extremely fast, with all the necessary features included."
            )}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
          <MDBox
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={-5}
          >
            <MDBox component="img" src={simple} width="70%" />

            <Img
              src={simple_1}
              left="-6%"
              top="8%"
              width="40%"
              sx={{
                animation: `${Floatingy} 11s infinite ease-in-out`,
              }}
            />

            <Img
              src={simple_2}
              left="5%"
              top="38%"
              width="26%"
              sx={{
                animation: `${Floatingx} 14s infinite ease-in-out`,
              }}
            />

            <Img
              src={simple_3}
              left="60%"
              top="53%"
              width="38%"
              sx={{
                animation: `${Floatingx} 10s infinite ease-in-out`,
              }}
            />
          </MDBox>
        </Grid>
      </Grid>

      <Grid container alignItems="center" pt={8} pb={10}>
        <Grid
          item
          md={6}
          pl={{ xs: 0, md: 6 }}
          sx={{ mr: { xs: 0, md: 3 } }}
          py={8}
          order={{ xs: 1, md: 2 }}
        >
          <MDTypography variant="h3">
            {t("Accept payments without limitations.")}
          </MDTypography>
          <MDTypography variant="body1" color="text" mb={3}>
            {t(
              "No need to limit your payment options - accept credit and debit cards, PayPal, bank transfers, PayMe, WeChat Pay, Alipay and more to make transactions a breeze."
            )}
          </MDTypography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ ml: "auto" }}
          order={{ xs: 2, md: 1 }}
          display="flex"
          justifyContent={{ xs: "center", md: "flex-start" }}
          alignItems="center"
        >
          <MDBox
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={-5}
            maxWidth={334}
          >
            <MasterCard
              number={4242424242424242}
              holder="jack peterson"
              expires={`09/${(new Date().getFullYear() + 4)
                .toString()
                .substr(-2)}`}
            />

            <Svg
              sx={{
                animation: `${Floatingx} 11s infinite ease-in-out`,
                backgroundColor: "#0077a6",
              }}
              left="-5%"
              top="-13%"
              component={AmericanExpressIcon}
            />

            <Svg
              sx={{
                animation: `${Floatingy} 14s infinite ease-in-out`,
              }}
              left="80%"
              top="70%"
              component={VisaIcon}
            />

            <Svg
              sx={{
                animation: `${Floatingy} 12s infinite ease-in-out`,
              }}
              left="44%"
              top="88%"
              component={PaypalIcon}
            />
          </MDBox>
        </Grid>
      </Grid>

      <Grid container alignItems="center" pt={8} pb={10}>
        <Grid
          item
          md={6}
          pl={{ xs: 0, md: 6 }}
          sx={{ mr: { xs: 0, md: 3 } }}
          py={8}
        >
          <MDTypography variant="h3">
            {t("Unlock store potential with in-depth insights.")}
          </MDTypography>
          <MDTypography variant="body1" color="text" mb={3}>
            {t(
              "Track your store's performance with detailed reporting on sales, revenue and customer behavior. Boost conversions and customer loyalty."
            )}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
          <MDBox
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py="10%"
            my={-5}
          >
            <MDBox
              component="img"
              src={analytics}
              width="80%"
              borderRadius="lg"
              shadow="md"
            />

            <Img
              src={analytics_1}
              left="-7%"
              top="-3%"
              width="18%"
              sx={{
                animation: `${Floatingx} 14s infinite ease-in-out`,
              }}
              shadow
            />

            <Img
              src={analytics_2}
              left="26%"
              top="1%"
              width="40%"
              sx={{
                animation: `${Floatingy} 11s infinite ease-in-out`,
              }}
              shadow
            />

            <Img
              src={analytics_3}
              width="40%"
              left="53%"
              top="80%"
              sx={{
                animation: `${Floatingx} 10s infinite ease-in-out`,
              }}
              shadow
            />
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
}
