import MDBox from "components/MDBox";
import LoadingIcon from "components/LoadingIcon";

import DashboardSection from "../DashboardSection";

import ShippingForm from "./ShippingForm";

import shippingZoneLocationsApi from "services/wc/shippingZoneLocations";
import shippingZoneMethodsApi from "services/wc/shippingZoneMethods";
import shippingZonesApi from "services/wc/shippingZones";

import useAsync from "libs/useAsync";

export default function Shipping() {
  const { value: shippingZones, refresh } = useAsync(async () => {
    const shippingZones = await shippingZonesApi.get();

    const [shippingZoneLocations, shippingZoneMethods] = await Promise.all([
      Promise.all(
        shippingZones.map((shippingZone) =>
          shippingZoneLocationsApi.get(shippingZone.id)
        )
      ),
      Promise.all(
        shippingZones.map((shippingZone) =>
          shippingZoneMethodsApi.get(shippingZone.id)
        )
      ),
    ]);

    const result = shippingZones.map((shippingZone, i) => ({
      ...shippingZone,
      methods: shippingZoneMethods[i],
      locations: shippingZoneLocations[i],
    }));

    return result;
  }, []);

  return (
    <DashboardSection
      title="Delivery"
      description="Set your delivery methods and prices"
    >
      {shippingZones ? (
        <ShippingForm shippingZones={shippingZones} onSubmit={refresh} />
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <LoadingIcon />
        </MDBox>
      )}
    </DashboardSection>
  );
}
