/*import ArticleIcon from "@mui/icons-material/Article";
import CampaignIcon from "@mui/icons-material/Campaign";
import GroupsIcon from "@mui/icons-material/Groups";
*/
import InventoryIcon from "@mui/icons-material/Inventory";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Footer from "components/Footer";
import NavBar from "components/NavBar";

import Loading from "routes/Loading/Loading";
import { Session } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { getSession } from "libs/session";
import config from "config";
import { getSite } from "libs/sites";

export default function AuthLayout() {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setSession(await getSession());
      setLoading(false);
    })();
  }, []);

  const [subdomain, setSubdomain] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const site = await getSite();
      if (!site) return;

      setSubdomain(site.subdomain);
    })();
  }, []);

  const location = useLocation();

  if (loading) return <Loading />;

  if (!session)
    return (
      <Navigate to="/signin" state={{ requestedLocation: location }} replace />
    );

  if (!subdomain) return <Loading />;

  return (
    <>
      <NavBar
        leftButtons={[
          {
            icon: <ListAltIcon />,
            title: "Orders",
            href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}/wp-admin/edit.php?post_type=shop_order`,
            color: "dark",
            variant: "text",
          },
          {
            icon: <InventoryIcon />,
            title: "Products",
            href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}/wp-admin/edit.php?post_type=product`,
            color: "dark",
            variant: "text",
          },
          {
            icon: <SignalCellularAltIcon />,
            title: "Analytics",
            href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}/wp-admin/admin.php?page=wc-admin&path=%2Fanalytics%2Foverview`,
            color: "dark",
            variant: "text",
          },
          /*
          {
            icon: <GroupsIcon />,
            title: "Customers",
            href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}/wp-admin/admin.php?page=wc-admin&path=%2Fcustomers`,
            color: "dark",
            variant: "text",
          },
          {
            icon: <ArticleIcon />,
            title: "Pages",
            href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}/wp-admin/edit.php?post_type=page`,
            color: "dark",
            variant: "text",
          },
          {
            icon: <CampaignIcon />,
            title: "Marketing",
            href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}/wp-admin/edit.php?post_type=shop_coupon`,
            color: "dark",
            variant: "text",
          },*/
        ]}
        rightButtons={[
          {
            icon: <SupportAgentIcon />,
            title: "Support",
            to: "/support",
            color: "dark",
            variant: "text",
          },
          {
            icon: <LogoutIcon />,
            title: "Sign out",
            to: "/signout",
            color: "dark",
            variant: "text",
          },
        ]}
      />
      <Outlet />
      <Footer />
    </>
  );
}
