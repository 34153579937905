import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Control, Controller, useController } from "react-hook-form";
import { TShippingZonesFormValues } from "./types";
import TextField from "@mui/material/TextField";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import MDButton from "components/MDButton";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { Divider } from "@mui/material";

export default function ShippingZoneMethod({
  control,
  shippingZoneIndex,
  shippingZoneMethodIndex,
  onRemove,
}: {
  control: Control<TShippingZonesFormValues>;
  shippingZoneIndex: number;
  shippingZoneMethodIndex: number;
  onRemove: () => void;
}) {
  const theme = useTheme();
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));

  const {
    field: costField,
    formState: { isSubmitting },
  } = useController({
    control,
    name: `shippingZones.${shippingZoneIndex}.methods.${shippingZoneMethodIndex}.settings.method_rules.value.0.cost_per_order`,
  });

  const { field: minField } = useController({
    control,
    name: `shippingZones.${shippingZoneIndex}.methods.${shippingZoneMethodIndex}.settings.method_rules.value.0.conditions.0.min`,
  });

  const { field: maxField } = useController({
    control,
    name: `shippingZones.${shippingZoneIndex}.methods.${shippingZoneMethodIndex}.settings.method_rules.value.0.conditions.0.max`,
  });

  const { field: descriptionField } = useController({
    control,
    name: `shippingZones.${shippingZoneIndex}.methods.${shippingZoneMethodIndex}.settings.method_description.value`,
  });

  const [isShowCost, setShowCost] = useState(!!costField.value);

  const [isShowConditions, setShowConditions] = useState(
    !!minField.value || !!maxField.value
  );

  const [isShowDescription, setShowDescription] = useState(
    !!descriptionField.value
  );

  return (
    <>
      <Box display="flex" flexDirection="row" columnGap={1}>
        <Box
          flex={1}
          display="flex"
          flexDirection={downXl ? "column" : "row"}
          alignItems={downXl ? undefined : "center"}
          columnGap={2}
          flexWrap="wrap"
        >
          <Box
            display="flex"
            flex={1}
            flexDirection="row"
            alignItems="center"
            minWidth={200}
          >
            <Controller
              control={control}
              name={`shippingZones.${shippingZoneIndex}.methods.${shippingZoneMethodIndex}.settings.method_title.value`}
              rules={{
                required: "This field is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="Title"
                  fullWidth
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={field.onBlur}
                  error={!!error}
                  helperText={error?.message}
                  disabled={isSubmitting}
                />
              )}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems={downXl ? undefined : "center"}
          >
            <FormControlLabel
              label={isShowCost ? undefined : "Delivery fee"}
              control={
                <Switch
                  checked={isShowCost}
                  onChange={async (e, checked) => {
                    setShowCost(checked);
                    costField.onChange("");
                  }}
                  disabled={isSubmitting}
                />
              }
            />
            {isShowCost && (
              <Box ml={-2} flex={1} minWidth={134} width={134}>
                <TextField
                  margin="dense"
                  label="Delivery fee"
                  type="number"
                  fullWidth
                  value={costField.value}
                  onChange={(e) => costField.onChange(e.target.value)}
                  onBlur={costField.onBlur}
                  disabled={isSubmitting}
                />
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems={downXl ? undefined : "center"}
          >
            <FormControlLabel
              label={isShowConditions ? undefined : "Conditions"}
              control={
                <Switch
                  checked={isShowConditions}
                  onChange={async (e, checked) => {
                    setShowConditions(checked);
                    minField.onChange("");
                    maxField.onChange("");
                  }}
                  disabled={isSubmitting}
                />
              }
            />
            {isShowConditions && (
              <Box
                display="flex"
                flexDirection="row"
                columnGap={1}
                ml={-2}
                flexWrap="wrap"
                flex={1}
              >
                <Box flex={1} minWidth={134} width={134}>
                  <TextField
                    margin="dense"
                    label="Cart total is from"
                    type="number"
                    value={minField.value}
                    onChange={(e) => minField.onChange(e.target.value)}
                    onBlur={minField.onBlur}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Box>
                <Box flex={1} minWidth={134} width={134}>
                  <TextField
                    margin="dense"
                    label="To"
                    type="number"
                    value={maxField.value}
                    onChange={(e) => maxField.onChange(e.target.value)}
                    onBlur={maxField.onBlur}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box
            width={isShowDescription ? "100%" : undefined}
            display="flex"
            flexDirection="row"
            alignItems={isShowDescription || downXl ? undefined : "center"}
          >
            <FormControlLabel
              label={isShowDescription ? undefined : "Instructions"}
              control={
                <Switch
                  checked={isShowDescription}
                  onChange={async (e, checked) => {
                    setShowDescription(checked);
                    descriptionField.onChange("");
                  }}
                  disabled={isSubmitting}
                />
              }
            />
            {isShowDescription && (
              <Box flex={1} ml={-2}>
                <RichTextEditor
                  label="Instructions to customer"
                  value={descriptionField.value}
                  onBlur={descriptionField.onBlur}
                  onChange={descriptionField.onChange}
                  editable={!isSubmitting}
                />
              </Box>
            )}
          </Box>
        </Box>

        <MDButton
          sx={{ lineHeight: 1.5, mt: 2 }}
          onClick={onRemove}
          variant="text"
          color="warning"
          size="small"
          iconOnly
          disabled={isSubmitting}
        >
          <ClearIcon />
        </MDButton>
      </Box>
      {downXl && <Divider />}
    </>
  );
}
