const manualOffsets = [
  { value: "-12", label: "UTC-12" },
  { value: "-11.5", label: "UTC-11:30" },
  { value: "-11", label: "UTC-11" },
  { value: "-10.5", label: "UTC-10:30" },
  { value: "-10", label: "UTC-10" },
  { value: "-9.5", label: "UTC-9:30" },
  { value: "-9", label: "UTC-9" },
  { value: "-8.5", label: "UTC-8:30" },
  { value: "-8", label: "UTC-8" },
  { value: "-7.5", label: "UTC-7:30" },
  { value: "-7", label: "UTC-7" },
  { value: "-6.5", label: "UTC-6:30" },
  { value: "-6", label: "UTC-6" },
  { value: "-5.5", label: "UTC-5:30" },
  { value: "-5", label: "UTC-5" },
  { value: "-4.5", label: "UTC-4:30" },
  { value: "-4", label: "UTC-4" },
  { value: "-3.5", label: "UTC-3:30" },
  { value: "-3", label: "UTC-3" },
  { value: "-2.5", label: "UTC-2:30" },
  { value: "-2", label: "UTC-2" },
  { value: "-1.5", label: "UTC-1:30" },
  { value: "-1", label: "UTC-1" },
  { value: "-0.5", label: "UTC-0:30" },
  { value: "0", label: "UTC+0" },
  { value: "0.5", label: "UTC+0:30" },
  { value: "1", label: "UTC+1" },
  { value: "1.5", label: "UTC+1:30" },
  { value: "2", label: "UTC+2" },
  { value: "2.5", label: "UTC+2:30" },
  { value: "3", label: "UTC+3" },
  { value: "3.5", label: "UTC+3:30" },
  { value: "4", label: "UTC+4" },
  { value: "4.5", label: "UTC+4:30" },
  { value: "5", label: "UTC+5" },
  { value: "5.5", label: "UTC+5:30" },
  { value: "5.75", label: "UTC+5:45" },
  { value: "6", label: "UTC+6" },
  { value: "6.5", label: "UTC+6:30" },
  { value: "7", label: "UTC+7" },
  { value: "7.5", label: "UTC+7:30" },
  { value: "8", label: "UTC+8" },
  { value: "8.5", label: "UTC+8:30" },
  { value: "8.75", label: "UTC+8:45" },
  { value: "9", label: "UTC+9" },
  { value: "9.5", label: "UTC+9:30" },
  { value: "10", label: "UTC+10" },
  { value: "10.5", label: "UTC+10:30" },
  { value: "11", label: "UTC+11" },
  { value: "11.5", label: "UTC+11:30" },
  { value: "12", label: "UTC+12" },
  { value: "12.75", label: "UTC+12:45" },
  { value: "13", label: "UTC+13" },
  { value: "13.75", label: "UTC+13:45" },
  { value: "14", label: "UTC+14" },
];

export default manualOffsets;
