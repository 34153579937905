import api from "services/wc/_api";

export type TSettingOption = {
  id: string;
  options: { [v: string]: string };
  value: string | string[];
};

export type TSettingOptionResponse = TSettingOption;

const settingOptions = {
  get: async (groupId: string, id: string): Promise<TSettingOptionResponse> =>
    (await api.get(`/settings/${groupId}/${id}`)).data,
};

export default settingOptions;
