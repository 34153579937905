import api from "services/wc/_api";

export type TShippingZone = {
  id: number;
  name: string;
  order: number;
};

export type TShippingZoneResponse = TShippingZone;
export type TShippingZonesResponse = TShippingZone[];

const shippingZones = {
  get: async (): Promise<TShippingZonesResponse> =>
    (await api.get("/shipping/zones")).data,

  put: async (
    id: number,
    data: {
      order: number;
      name: string;
    }
  ): Promise<TShippingZoneResponse> =>
    (await api.put(`/shipping/zones/${id}`, data)).data,

  post: async (data: {
    order: number;
    name: string;
  }): Promise<TShippingZoneResponse> =>
    (await api.post("/shipping/zones", data)).data,

  delete: async (id: number): Promise<TShippingZoneResponse> =>
    (await api.delete(`/shipping/zones/${id}?force=true`)).data,
};

export default shippingZones;
