import MDBox from "components/MDBox";
import LoadingIcon from "components/LoadingIcon";

import DashboardSection from "../DashboardSection";

import SettingsForm from "./SettingsForm";
import useApi from "libs/useApi";
import settings from "services/core/settings";

export default function Settings() {
  const { data, refresh } = useApi(settings.get);

  return (
    <DashboardSection title="Store details" description="Update your details">
      {data ? (
        <SettingsForm settings={data} onSubmit={refresh} />
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <LoadingIcon />
        </MDBox>
      )}
    </DashboardSection>
  );
}
