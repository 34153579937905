import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export default function LoadingIcon({
  sx,
  size,
}: {
  sx?: SxProps<Theme>;
  size?: number | string;
}) {
  return <CircularProgress sx={sx} size={size} color="secondary" />;
}
