import LoginIcon from "@mui/icons-material/Login";
import LanguageIcon from "@mui/icons-material/Language";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

export default function Layout() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <NavBar
        rightButtons={[
          {
            icon: <SupportAgentIcon />,
            title: t("Support"),
            to: "/support",
            color: "dark",
            variant: "text",
          },
          {
            icon: <LoginIcon />,
            title: t("Customer log in"),
            to: "/u",
            color: "dark",
            variant: "text",
          },
          {
            title: t("Pricing / Demo"),
            onClick: () => {
              // @ts-ignore: Event snippet for eShopRocket Price Button Clicked conversion page -->
              gtag("event", "conversion", {
                send_to: "AW-965382839/YKnrCOjti6gZELelqswD",
              });

              navigate("/#pricing");
              setTimeout(
                () => document.getElementById("pricing")?.scrollIntoView(),
                100
              );
            },
            color: "info",
            variant: "gradient",
          },
          {
            icon: <LanguageIcon />,
            title: i18n.language === "zh-Hant-HK" ? "English" : "中文",
            onClick: () => {
              i18n.changeLanguage(
                i18n.language === "zh-Hant-HK" ? "en" : "zh-Hant-HK"
              );
            },
            color: "dark",
            variant: "text",
          },
        ]}
      />
      <Outlet />
      <Footer />
    </>
  );
}
