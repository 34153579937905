import { Session } from "@supabase/supabase-js";
import supabase from "./supabase";
import cache from "./cache";

function onAuthStateChange(callback: (session: Session | null) => void) {
  const { unsubscribe } = supabase.auth.onAuthStateChange((event, session) => {
    callback(session);
  }).data.subscription;

  // cross browser tabs
  const handleStorage = (e: StorageEvent) => {
    if (e.key !== "sb-localhost-auth-token") return;

    const newSession = e.newValue ? JSON.parse(e.newValue) : null;

    callback(newSession?.currentSession);
  };
  window.addEventListener("storage", handleStorage);

  return () => {
    unsubscribe();
    window.removeEventListener("storage", handleStorage);
  };
}

const getSession = cache(
  async () => (await supabase.auth.getSession()).data.session,
  onAuthStateChange
);

export { getSession, onAuthStateChange };
