import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import AddIcon from "@mui/icons-material/Add";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import UploadingImage from "./UploadingImage";
import Stack from "@mui/material/Stack";

export default function AddSlideButton({
  onUploaded,
  height,
  multiple = true,
}: {
  onUploaded: (ids: number[]) => void;
  height: number;
  multiple?: boolean;
}) {
  const [uploadingFiles, setUploadingFiles] = useState<
    { key: string; file: File; id?: number; completed: boolean }[]
  >([]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { "image/*": [] },
    noClick: true,
    multiple,
    onDrop: (files) =>
      setUploadingFiles((uploadingFiles) => [
        ...uploadingFiles,
        ...files.map((file) => ({
          key: uuidv4(),
          file,
          completed: false,
        })),
      ]),
  });

  useEffect(() => {
    if (!uploadingFiles.length) return;

    const isAllCompleted = !uploadingFiles.find(({ completed }) => !completed);

    if (!isAllCompleted) return;

    onUploaded(
      uploadingFiles.filter(({ id }) => id).map(({ id }) => id) as number[]
    );

    setUploadingFiles([]);
  }, [onUploaded, uploadingFiles]);

  return (
    <Stack direction="row" alignItems="flex-start">
      {uploadingFiles.map(({ key, file }) => (
        <UploadingImage
          key={key}
          image={file}
          height={height}
          onCompleted={(id?: number) => {
            setUploadingFiles((uploadingFiles) => {
              const newUploadingFiles = [...uploadingFiles];

              const index = newUploadingFiles.findIndex(
                (uploadingFile) => uploadingFile.key === key
              );

              if (index === -1) return uploadingFiles;

              const newUploadingFile = newUploadingFiles[index];

              newUploadingFiles[index] = {
                ...newUploadingFile,
                id,
                completed: true,
              };

              return newUploadingFiles;
            });
          }}
        />
      ))}

      {(multiple || uploadingFiles.length === 0) && (
        <MDBox p={2} {...getRootProps()}>
          <input {...getInputProps()} />
          <MDButton
            variant="outlined"
            color="dark"
            iconOnly
            onClick={open}
            size="large"
            sx={{ minWidth: height, minHeight: height }}
          >
            <AddIcon />
          </MDButton>
        </MDBox>
      )}
    </Stack>
  );
}
