import { TImage } from "services/types";
import { getSite } from "./sites";

type TCachedImages = {
  [key: string]: TImage & { _file?: File };
};

let cachedImages: TCachedImages = {};

const images = {
  get(imageId: number) {
    return cachedImages[imageId];
  },
  async url(imageId: number, size: keyof TImage["metadata"]["sizes"]) {
    const image = cachedImages[imageId];
    if (!image) return null;

    const site = await getSite();
    if (!site) return null;

    const file = image.metadata.sizes[size]?.file;

    return `${file ? image.src.replace(/[^/]+$/, file) : image.src}`;
  },
  add(images: TCachedImages) {
    cachedImages = {
      ...cachedImages,
      ...images,
    };
  },
};

export default images;
