import DeleteIcon from "@mui/icons-material/Delete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import ShippingZoneMethods from "./ShippingZoneMethods";
import { Control, useController } from "react-hook-form";
import { TShippingZonesFormValues } from "./types";
import Box from "@mui/material/Box";
import { ShippingZoneLocations } from "./ShippingZoneLocations";

export default function ShippingZone({
  control,
  shippingZoneIndex,
  onRemove,
}: {
  control: Control<TShippingZonesFormValues>;
  shippingZoneIndex: number;
  onRemove: () => void;
}) {
  const { field } = useController({
    control,
    name: `shippingZones.${shippingZoneIndex}`,
  });

  return (
    <MDBox
      bgColor="grey-100"
      borderRadius="lg"
      p={2}
      px={3}
      display="flex"
      flexDirection="column"
      gap={1}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        minHeight={32}
      >
        <MDTypography
          sx={{ flex: 1 }}
          variant="button"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {field.value.id === 0
            ? "Locations not covered by your other zones"
            : "Zone"}
        </MDTypography>

        {field.value.id !== 0 && (
          <MDButton
            onClick={onRemove}
            variant="text"
            color="warning"
            size="small"
            startIcon={<DeleteIcon />}
            sx={{ lineHeight: 1.5 }}
          >
            Delete
          </MDButton>
        )}
      </Box>

      {field.value.id !== 0 && (
        <ShippingZoneLocations
          control={control}
          shippingZoneIndex={shippingZoneIndex}
        />
      )}

      <ShippingZoneMethods
        control={control}
        shippingZoneIndex={shippingZoneIndex}
      />
    </MDBox>
  );
}
