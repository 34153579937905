import type {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

import axios from "axios";
import axiosRetry from "axios-retry";
import config from "config";

import { getSite } from "libs/sites";
import supabase from "libs/supabase";

export function create({
  namespace,
  onRequest,
  onResponse,
  retryCondition,
}: {
  namespace: string;
  onRequest?: (
    response: InternalAxiosRequestConfig
  ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
  onResponse?: (
    response: AxiosResponse
  ) => AxiosResponse | Promise<AxiosResponse>;
  retryCondition?: (error: AxiosError) => boolean | Promise<boolean>;
}) {
  const instance = axios.create();

  instance.defaults.headers.common["Content-Type"] = "application/json";

  instance.interceptors.request.use(async (requestConfig) => {
    const site = await getSite();
    if (!site) return requestConfig;

    requestConfig.baseURL = `${config.wpProtocol}//${site.subdomain}.${config.wpDomain}/wp-json/${namespace}/`;

    return requestConfig;
  });

  instance.interceptors.request.use(async (config) => {
    const token = (await supabase.auth.getSession()).data.session?.access_token;

    if (token) config.headers.set("Admin-Token", token);

    return config;
  });

  instance.interceptors.request.use(onRequest);

  instance.interceptors.response.use(onResponse);

  // make sure axiosRetry's interceptors is last interceptor to prevent fire failed interceptors.
  axiosRetry(instance, {
    retries: Infinity,
    retryDelay: () => 5000,
    retryCondition: async (error) => {
      // retries = 5 if isNetworkError === false
      const retryState = error.config?.["axios-retry"] as
        | { retryCount?: number }
        | undefined;

      if (
        retryState?.retryCount &&
        retryState?.retryCount > 5 &&
        !axiosRetry.isNetworkError(error)
      )
        return false;

      return (
        (await retryCondition?.(error)) ||
        axiosRetry.isNetworkOrIdempotentRequestError(error)
      );
    },
  });

  // don't return instance directly to prevent add interceptors in some other palce and make sure axiosRetry's interceptors is last interceptor
  return {
    get: instance.get.bind(instance),
    post: instance.post.bind(instance),
    delete: instance.delete.bind(instance),
    put: instance.put.bind(instance),
  };
}
