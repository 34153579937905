import { Session } from "@supabase/supabase-js";
import { getSession, onAuthStateChange } from "libs/session";
import supabase from "libs/supabase";
import cache from "./cache";
import pRetry from "p-retry";

export type Site = {
  subdomain: string;
};

async function getSitesBySession(
  session: Session | null
): Promise<Site[] | null> {
  if (!session) return null;

  const sites = await pRetry(
    async () => {
      const { data: sites, error } = await supabase
        .from("sites")
        .select("*")
        .eq("user_id", session?.user.id);

      /*
            supabase
              .channel("public:aaa")
              .on(
                "postgres_changes",
                { event: "*", schema: "public", table: "aaa" },
                (payload) => {
                  console.log("Change received!", payload);
                }
              )
              .subscribe();*/

      if (error) {
        // fix JWT error, e.g. expire
        if (error.code.includes("PGRST")) {
          await supabase.auth.signOut();
          window.location.reload();
          return null;
        }

        throw error;
      }

      if (!sites.length) throw new Error("sites.length === 0");

      return sites;
    },
    { forever: true }
  );

  return sites;
}

function onSitesChange(callback: (sites: Site[] | null) => void) {
  onAuthStateChange(async (session) => {
    callback(await getSitesBySession(session));
  });
}

const getSites = cache(
  async () => getSitesBySession(await getSession()),
  onSitesChange
);

async function getSite() {
  return (await getSites())?.[0] ?? null;
}

export { getSites, getSite };
