import { decodeBlurHash } from "fast-blurhash";
import { useEffect, useMemo, useRef, useState } from "react";
import images from "libs/images";
import { TImage } from "services/types";
import MDBox from "./MDBox";
import ShadowImage from "./ShadowImage";

export default function WPImage({
  id,
  size,
  width,
  height,
}: {
  id: number;
  size: keyof TImage["metadata"]["sizes"];
  width: number;
  height: number;
}) {
  const [url, setUrl] = useState<string | null>(null);
  const [objectUrl, setObjectUrl] = useState<string | null>(null);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const image = useMemo(() => images.get(id), [id]);

  useEffect(() => {
    if (!image) return;

    const canvas = canvasRef.current;

    if (!canvas) return;

    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    if (image._file) {
      const objectUrl = URL.createObjectURL(image._file);
      setObjectUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }

    const imageData = ctx.createImageData(width, height);
    const pixels = decodeBlurHash(image.blurhash, width, height);
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
  }, [height, image, width]);

  useEffect(() => {
    (async () => {
      setUrl(await images.url(id, size));
    })();
  }, [id, size]);

  return (
    <MDBox
      component="a"
      href={image.src}
      target="_blank"
      position="relative"
      width={width}
      height={height}
    >
      {!isLoaded &&
        (objectUrl ? (
          <ShadowImage width={width} height={height} src={objectUrl} />
        ) : (
          <MDBox shadow="md" borderRadius="lg" overflow="hidden" display="flex">
            <canvas ref={canvasRef} width={width} height={height} />
          </MDBox>
        ))}

      {url && (
        <ShadowImage
          onLoad={() => {
            delete images.get(id)._file;
            setIsLoaded(true);
          }}
          opacity={isLoaded ? 1 : 0}
          width={width}
          height={height}
          src={url}
        />
      )}
    </MDBox>
  );
}
