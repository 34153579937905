import { AxiosResponse } from "axios";
import api from "services/core/_api";
import { TImage } from "services/types";

export type TSlide = {
  image_id: number;
};

export type TCategorySlide = TSlide & {
  type: "category";
  category_id?: number;
};

export type TUrlSlide = TSlide & {
  type: "url";
  url?: string;
};

export type TBannerBlock = {
  type: "banner";
  slides: (TCategorySlide | TUrlSlide)[];
};

export type TCategoryBlock = {
  type: "category";
  category_id?: number;
};

export type TBlocks = (TBannerBlock | TCategoryBlock)[];

export type TLayout = {
  header?: {
    blocks?: TBlocks;
  };
  blocks?: TBlocks;
  popup?: {
    slides?: (TCategorySlide | TUrlSlide)[];
  };
};

export type TLayoutRequest = TLayout;

export type TLayoutResponse = TLayout & {
  _images: {
    [key: string]: TImage;
  };
};

type TListener = (response: AxiosResponse) => void;
let listeners: TListener[] = [];

export function onResponse(callback: TListener) {
  listeners = [...listeners, callback];
  return () => {
    listeners = listeners.filter((l) => l !== callback);
  };
}

function handleResponse(response: AxiosResponse) {
  for (let listener of listeners) listener(response);
  return response;
}

const layouts = {
  get: async (): Promise<TLayoutResponse> =>
    handleResponse(await api.get("admin/layouts/shop")).data,
  post: async (data: TLayoutRequest): Promise<TLayoutResponse> =>
    handleResponse(await api.post("admin/layouts/shop", data)).data,
};

export default layouts;
