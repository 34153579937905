import SvgIcon from "@mui/material/SvgIcon";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingIcon from "components/LoadingIcon";

import { TPaymentGatewaysResponse } from "services/core/paymentGateways";
import Box from "@mui/material/Box";

export default function PaymentGateway<
  TValues extends TPaymentGatewaysResponse[keyof TPaymentGatewaysResponse]
>({
  Icon,
  title,
  description,
  values,
  onValuesChange,
  onSubmit,
  validate,
  ContentComponent,
}: {
  Icon: typeof SvgIcon;
  title: string;
  description: React.ReactNode;
  values?: TValues;
  onValuesChange: (values: TValues) => void;
  onSubmit: (values: TValues) => void;
  validate?: (values: TValues) => boolean;
  inputs?: (values: TValues) => { title: string; value: string }[];
  ContentComponent?: ({
    values,
    onClose,
    onSubmit,
    validate,
  }: {
    values: TValues;
    onClose: (values: TValues) => void;
    onSubmit: (values: TValues) => void;
    validate?: (values: TValues) => boolean;
  }) => JSX.Element;
}) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <MDBox display="flex" flexDirection="row">
        <Icon fontSize="large" />

        <MDBox
          display="flex"
          flex={1}
          ml={2}
          alignItems="flex-start"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box flex={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {title}
            </MDTypography>
            <Box display="flex" alignItems="flex-end">
              <MDTypography variant="button" color="text">
                {description}
              </MDTypography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            width={116}
            mt={{ xs: 1, md: 0 }}
            ml={{ xs: 0, md: 1 }}
          >
            {!values || loading ? (
              <Box mr={3} display="flex" flex={1} justifyContent="flex-end">
                <LoadingIcon size={20} />
              </Box>
            ) : (
              <>
                <MDBox lineHeight={0} flex={1}>
                  <MDTypography variant="button" color="text">
                    {values.enabled ? "Enabled" : "Disabled"}
                  </MDTypography>
                </MDBox>
                <Switch
                  checked={values.enabled}
                  onChange={async (e, checked) => {
                    const newValues = { ...values, enabled: checked };

                    onValuesChange(newValues);
                    if (validate && !validate?.(newValues)) return;

                    setLoading(true);
                    await onSubmit(newValues);
                    setLoading(false);
                  }}
                />
              </>
            )}
          </Box>
        </MDBox>
      </MDBox>
      {values && !!ContentComponent && values.enabled && (
        <MDBox ml={2} pl={4} pt={1} lineHeight={1}>
          <ContentComponent
            values={values}
            onClose={(values) => {
              if (validate && !validate(values)) {
                onValuesChange({ ...values, enabled: false });
              }
            }}
            onSubmit={async (values) => {
              const newValues = { ...values };
              if (validate && !validate?.(values)) newValues.enabled = false;

              await onSubmit(newValues);

              onValuesChange(newValues);
            }}
            validate={validate}
          />
        </MDBox>
      )}
      <Divider />
    </>
  );
}
