import WhatsApp from "@mui/icons-material/WhatsApp";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { forwardRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import SaveButton from "routes/u/Dashboard/SaveButton";

import orderNotifications from "services/core/orderNotifications";
import { TOrderNotificationsResponse } from "services/core/orderNotifications";

import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

const PhoneTextField = forwardRef(function (
  props: TextFieldProps & { onChange: (v: string) => void },
  ref
) {
  const handleChange = (e: { target: { value: any } }) => {
    props.onChange(e.target.value);
  };

  return <TextField inputRef={ref} {...props} onChange={handleChange} />;
});

export default function OrderNotificationForm({
  data,
  onSubmit,
}: {
  data: TOrderNotificationsResponse;
  onSubmit: () => void;
}) {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({ defaultValues: data });

  const handleSave = handleSubmit(async (data) => {
    // send
    await orderNotifications.post(data);
    await onSubmit();
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset(data);
  }, [data, isSubmitSuccessful, reset]);

  return (
    <MDBox>
      <MDBox display="flex" alignItems="center" mb={1}>
        <WhatsApp fontSize="large" />

        <PhoneInputWithCountry
          style={{ marginLeft: 16, flex: 1 }}
          name="whatsapp"
          defaultCountry="HK"
          control={control}
          rules={{ required: "This field is required" }}
          inputComponent={PhoneTextField}
          margin="dense"
          label="WhatsApp"
          error={!!errors.whatsapp}
          helperText={errors.whatsapp?.message}
        />
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </MDBox>
  );
}
