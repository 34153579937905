import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import MDButton from "components/MDButton";
import SaveButton from "routes/u/Dashboard/SaveButton";

import shippingZonesApi from "services/wc/shippingZones";
import shippingZoneMethodsApi, {
  TFlexibleShippingSingle,
} from "services/wc/shippingZoneMethods";
import Box from "@mui/material/Box";
import ShippingZone from "./ShippingZone";
import {
  TShippingZoneWithLocationsAndMethods,
  TShippingZonesFormValues,
} from "./types";
import shippingZoneLocations from "services/wc/shippingZoneLocations";
import { useRichTextEditors } from "components/RichTextEditor/useRichTextEditors";

export default function ShippingForm({
  shippingZones,
  onSubmit,
}: {
  shippingZones: TShippingZoneWithLocationsAndMethods[];
  onSubmit: () => void;
}) {
  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
    getValues,
    control,
    reset,
  } = useForm<TShippingZonesFormValues>({
    defaultValues: { shippingZones },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "shippingZones",
  });

  const { uploadImages } = useRichTextEditors();

  const handleSave = handleSubmit(async (data) => {
    await uploadImages();

    const { shippingZones: newShippingZones } = getValues();

    await Promise.all([
      // update and add zones
      ...newShippingZones.map(async (shippingZone, i) => {
        const methods = shippingZone.methods.filter(
          (o): o is TFlexibleShippingSingle =>
            o.method_id === "flexible_shipping_single"
        );

        const name =
          shippingZone.locations.map(({ code }) => code).join(", ") ||
          shippingZone.name;

        const shippingZoneId =
          shippingZone.id ??
          (await shippingZonesApi.post({ name, order: i })).id;

        await Promise.all([
          // name
          shippingZone.id &&
            shippingZonesApi.put(shippingZone.id, { name, order: i }),

          // locations
          shippingZone.id !== 0 &&
            shippingZoneLocations.put(shippingZoneId, shippingZone.locations),

          // methods: update and add
          ...methods.map((method, i) => {
            const method_title = method.settings.method_title.value;
            const method_description = method.settings.method_description.value;
            const method_rules = method.settings.method_rules.value[0];

            const settings = {
              method_free_shipping_label: "",
              method_title,
              method_description,
              method_rules: JSON.stringify([
                {
                  conditions: [
                    {
                      condition_id: "value",
                      min: method_rules.conditions[0].min,
                      max: method_rules.conditions[0].max,
                    },
                  ],
                  cost_per_order:
                    method.settings.method_rules.value[0].cost_per_order,
                  additional_costs: [],
                  special_action: "",
                },
              ]),
            };

            return method.id
              ? shippingZoneMethodsApi.put(shippingZoneId, method.id, {
                  order: i,
                  settings,
                })
              : shippingZoneMethodsApi.post(shippingZoneId, {
                  method_id: "flexible_shipping_single",
                  order: i,
                  settings,
                });
          }),

          // methods: delete
          ...(shippingZones
            .find(({ id }) => shippingZone.id === id)
            ?.methods.filter(
              ({ id }) => !shippingZone.methods.find((o) => o.id === id)
            )
            .map(({ id }) =>
              shippingZoneMethodsApi.delete(shippingZoneId, id)
            ) ?? []),
        ]);
      }),

      // delete zones
      ...shippingZones
        .filter(({ id }) => !data.shippingZones.find((o) => o.id === id))
        .map(({ id }) => shippingZonesApi.delete(id)),
    ]);

    await onSubmit();
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset({ shippingZones });
  }, [shippingZones, isSubmitSuccessful, reset]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {fields.map((field, i) => (
        <ShippingZone
          key={field.id}
          control={control}
          shippingZoneIndex={i}
          onRemove={() => remove(i)}
        />
      ))}

      <MDButton
        onClick={() => append({ name: uuidv4() })}
        variant="text"
        color="dark"
        size="small"
      >
        + Add delivery zone
      </MDButton>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </Box>
    </Box>
  );
}
