import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingIcon from "components/LoadingIcon";
import MDButton from "components/MDButton";

export default function StripeDialog({
  open,
  onClose,
  onSubmit,
  loading,
  title,
  description,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  title: string;
  description: string;
}) {
  return (
    <Dialog open={open} onClose={loading ? undefined : onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText whiteSpace="pre-line">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={onClose}
          variant="text"
          color="dark"
          disabled={loading}
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={onSubmit}
          disabled={loading}
          variant="gradient"
          color="dark"
          size="small"
        >
          {loading && <LoadingIcon sx={{ marginRight: 1 }} size={20} />}
          Proceed
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
