import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import LoadingIcon from "components/LoadingIcon";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import config from "config";

import supabase from "libs/supabase";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const defaultValues = {
    email: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const handleRecover = handleSubmit(async ({ email }) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });

    if (error) {
      setErrorMsg(`${error.message} (${error.name})`);
      setOpenErrorAlert(true);
      console.error(error);
      return;
    }

    navigate("/signin");
  });

  return (
    <Grid
      p={1}
      pt={11}
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} sm={8} md={6}>
        <Snackbar
          open={openErrorAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setOpenErrorAlert(false)}
        >
          <Alert
            onClose={() => setOpenErrorAlert(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardContent>
            <form onSubmit={handleRecover}>
              <Stack spacing={1}>
                <MDTypography variant="h3" gutterBottom textAlign="center">
                  {`Forgot your password?`}
                </MDTypography>
                <MDTypography variant="button" gutterBottom>
                  {`Enter your email address below and we’ll send you password reset instructions.`}
                </MDTypography>

                <TextField
                  label="Enter your email address…"
                  variant="standard"
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />

                <MDTypography variant="button" gutterBottom py={2}>
                  {`If you’d like some extra help, just email our support team at `}
                  <Link href={`mailto:${config.supportEmail}`}>
                    {config.supportEmail}
                  </Link>
                </MDTypography>

                <MDButton onClick={() => navigate(-1)}>
                  Never mind, go back
                </MDButton>
              </Stack>
              <CardActions>
                <MDButton
                  variant="gradient"
                  color="dark"
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <LoadingIcon sx={{ marginRight: 1 }} size={20} />
                  )}
                  Email me reset instructions
                </MDButton>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
