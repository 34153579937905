import MDButton from "components/MDButton";
import LoadingIcon from "components/LoadingIcon";

export default function SaveButton({
  onClick,
  loading,
}: {
  onClick: () => void;
  loading: boolean;
}) {
  return (
    <MDButton
      onClick={onClick}
      variant="gradient"
      color="dark"
      size="small"
      disabled={loading}
    >
      {loading && <LoadingIcon sx={{ marginRight: 1 }} size={20} />}
      Save
    </MDButton>
  );
}
