import Grid from "@mui/material/Grid";
import Box from "@mui/material/Grid";

import MDTypography from "components/MDTypography";

import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import UpdateIcon from "@mui/icons-material/Update";
import BuildIcon from "@mui/icons-material/Build";
import IntroCard from "routes/Home/Intro/IntroCard";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { useTranslation } from "react-i18next";
import config from "config";

export default function Intro() {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        rowSpacing={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxWidth="lg"
        m="auto"
        pb={1}
      >
        <Grid item xs={12}>
          <MDTypography
            variant="h2"
            fontSize={{ xs: "1.875rem", sm: "2.0625rem", md: "2.25rem" }}
            textAlign="center"
            gutterBottom
          >
            {t("Develop Mobile Apps and Websites with Zero Upfront Cost")}
          </MDTypography>

          <MDTypography variant="body1" textAlign="center">
            {t(
              "Experience mobile app and website development without any upfront fees. Our service includes unlimited feature updates, custom-tailored to your needs by real developers—not just plugins or templates. Our tech support team consists of real humans providing real-time assistance in Cantonese and English from 8:00 AM to 11:59 PM HKT via WhatsApp and telephone. Pay only a {{from}} to {{to}} fee per transaction, simplifying your expansion with no hidden costs.",
              config.price
            )}
          </MDTypography>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" flexDirection="column" mt={5}>
        <Grid container maxWidth="lg" rowSpacing={5} columnSpacing={5} pb={10}>
          <Grid item md={6}>
            <IntroCard
              icon={<MoneyOffIcon />}
              title={t("Zero Upfront Costs")}
              description={t(
                "Launch your custom e-commerce app and website with us, and you won't pay a dime until your business starts seeing success. We invest our expertise in you because we believe in your vision."
              )}
            />
          </Grid>

          <Grid item md={6}>
            <IntroCard
              icon={<BuildIcon />}
              title={t("Tailored to Your Needs")}
              description={t(
                "Forget one-size-fits-all solutions. Your business is unique, and your digital tools should be too. We work closely with you to develop and maintain custom features that drive your business forward."
              )}
            />
          </Grid>

          <Grid item md={6}>
            <IntroCard
              icon={<HandshakeIcon />}
              title={t("Close Communication")}
              description={t(
                "Say goodbye to the frustration of miscommunication with distant IT firms. Our partnership model ensures you have a dedicated, understanding partner in every step of your app's journey."
              )}
            />
          </Grid>

          <Grid item md={6}>
            <IntroCard
              icon={<UpdateIcon />}
              title={t("Continuous Updates, No Extra Charge")}
              description={t(
                "The digital market doesn't stand still, and neither should your app. We provide ongoing updates to keep you competitive, all included in our success-based partnership."
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
