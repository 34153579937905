import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";

export default function Pricing() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("Help is on the way!")} | ${config.name}`;
  }, [t]);

  return (
    <Box
      pt={10}
      height="90vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography fontWeight="bold" textAlign="center" variant="h2">
        {t("We’ll get back to you soon.")}
      </Typography>
    </Box>
  );
}
