import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import SaveButton from "routes/u/Dashboard/SaveButton";
import { TCodRequest } from "services/core/paymentGateways";

export default function Cod({
  values,
  onSubmit,
}: {
  values: TCodRequest;
  onSubmit: (values: TCodRequest) => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      description: values.description || "Pay with cash upon delivery.",
    },
  });

  const handleSave = handleSubmit(
    async ({ description }: { description: string }) => {
      const newValues = { ...values, description };

      await onSubmit(newValues);
    }
  );

  useEffect(() => {
    if (isSubmitSuccessful)
      reset({
        description: values.description || "Pay with cash upon delivery.",
      });
  }, [values.description, isSubmitSuccessful, reset]);

  return (
    <Stack mt={2} spacing={2}>
      <TextField
        margin="dense"
        label="Bank information"
        {...register("description", {
          required: "This field is required",
        })}
        error={!!errors.description}
        helperText={errors.description?.message}
        fullWidth
      />

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </Stack>
  );
}
