export default function cache<T>(
  init: () => Promise<T | null>,
  updater?: (update: (value: T | null) => void) => void
) {
  let value: T | null = null;
  let promise: Promise<T | null> | null = null;

  updater?.((newValue) => {
    value = newValue;
  });

  return async () => {
    if (value) return value;

    if (!promise)
      promise = (async () => {
        value = await init();

        // remove promise after updated value, because promise cached old value
        promise = null;
        return value;
      })();

    await promise;

    return value;
  };
}
