import api from "services/wc/_api";

export type TDataContinent = {
  code: string;
  name: string;
  countries: {
    code: string;
    name: string;
    states: {
      code: string;
      name: string;
    }[];
  }[];
};

export type TDataContinentsResponse = TDataContinent[];

const dataContinents = {
  get: async (): Promise<TDataContinentsResponse> =>
    (await api.get("/data/continents")).data,
};

export default dataContinents;
