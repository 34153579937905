import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
} from "react";

const RichTextEditorContext = createContext({
  uploadImages: () => {},
  addRichTextEditor: (v: () => Promise<void>) => {},
});

export function useRichTextEditors() {
  return useContext(RichTextEditorContext);
}

export function RichTextEditorsProvider({ children }: { children: ReactNode }) {
  const richTextEditorUploadImagesRef = useRef(new Set<() => Promise<void>>());

  const uploadImages = useCallback(
    () =>
      Promise.all(
        Array.from(richTextEditorUploadImagesRef.current).map((uploadImages) =>
          uploadImages()
        )
      ),
    []
  );

  const addRichTextEditor = useCallback((uploadImages: () => Promise<void>) => {
    richTextEditorUploadImagesRef.current.add(uploadImages);

    return () => richTextEditorUploadImagesRef.current.delete(uploadImages);
  }, []);

  return (
    <RichTextEditorContext.Provider value={{ uploadImages, addRichTextEditor }}>
      {children}
    </RichTextEditorContext.Provider>
  );
}
