import config from "config";
import { create } from "services/_api";
import images from "libs/images";

export default create({
  namespace: config.server.shopApiNamespace,
  onResponse: async (response) => {
    const { data } = response;

    if (data._images) images.add(data._images);

    return response;
  },
});
