import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { TBannerBlock, TCategoryBlock, TLayout } from "services/core/layouts";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useMemo, useState } from "react";
import { Control, Controller, useFieldArray } from "react-hook-form";
import SlidesEditor from "components/SlidesEditor/SlidesEditor";
import ProductCategoryAutocomplete from "components/ProductCategoryAutocomplete";

export default function BlocksEditor({
  control,
  name,
  blockOptions,
}: {
  control: Control<TLayout>;
  name: "header.blocks" | "blocks";
  blockOptions: { label: string; value: TBannerBlock | TCategoryBlock }[];
}) {
  const { fields, move, remove, append } = useFieldArray({
    control,
    name,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    move(result.source.index, result.destination.index);
  };

  const blocks = useMemo(() => {
    const usedDraggableId: { [key: string]: boolean } = {};

    return fields.map((block, index) => {
      let draggableId = JSON.stringify(block);
      if (usedDraggableId[draggableId]) draggableId = `${draggableId}${index}`;

      usedDraggableId[draggableId] = true;

      return {
        draggableId,
        item: block,
      };
    });
  }, [fields]);

  return (
    <Stack>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <MDBox {...provided.droppableProps} ref={provided.innerRef}>
              {blocks.map(({ draggableId, item }, index) => (
                <Draggable
                  key={draggableId}
                  draggableId={draggableId}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <MDBox
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{
                        userSelect: "none",
                      }}
                      component="li"
                      display="flex"
                      flexDirection="column"
                      pt={1}
                      pb={1}
                    >
                      <MDBox
                        opacity={snapshot.isDragging ? 0.5 : 1}
                        bgColor="grey-100"
                        borderRadius="lg"
                        p={1}
                        pl={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <MDBox
                          flex={1}
                          sx={{ overflowX: "scroll" }}
                          // prevent cutoff shodow
                          m={-3}
                          p={3}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {item.type}
                            </MDTypography>
                            <MDButton
                              onClick={() => remove(index)}
                              variant="text"
                              color="warning"
                              size="small"
                              startIcon={<DeleteIcon />}
                              sx={{ lineHeight: 1.5 }}
                            >
                              Delete
                            </MDButton>
                          </Stack>

                          {item.type === "banner" ? (
                            <SlidesEditor
                              control={control}
                              name={`${name}.${index}.slides`}
                            />
                          ) : item.type === "category" ? (
                            <Controller
                              name={`${name}.${index}.category_id`}
                              control={control}
                              render={({
                                field: { value, onChange, onBlur },
                              }) => (
                                <ProductCategoryAutocomplete
                                  value={value}
                                  onChange={(v) => {
                                    onChange(v);
                                    onBlur();
                                  }}
                                />
                              )}
                            />
                          ) : null}
                        </MDBox>
                        <MDBox
                          {...provided.dragHandleProps}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          p={1}
                        >
                          <DragHandleIcon />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </MDBox>
          )}
        </Droppable>
      </DragDropContext>

      <MDButton
        onClick={
          blockOptions.length > 1
            ? (e) => setAnchorEl(e.currentTarget)
            : () => append(blockOptions[0].value)
        }
        variant="outlined"
        color="dark"
        size="small"
      >
        + Add New
      </MDButton>

      {blockOptions.length > 1 && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {blockOptions.map(({ label, value }) => (
            <MenuItem
              key={label}
              onClick={() => {
                append(value);
                setAnchorEl(null);
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Stack>
  );
}
