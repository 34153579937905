import { useEffect } from "react";
import { useLocation, Location } from "react-router-dom";

const locationHistory: Location[] = [];

export function emailHtml() {
  let msg = `URL: ${window.location.href}`;

  msg += "<br /><br />";

  msg += "Location History:";

  msg += "<br />";

  msg += locationHistory
    .map((location) =>
      Object.entries(location).map(([key, value]) => `${key}: ${value}`)
    )
    .join("<br />");

  return msg;
}

export default function LocationHistory() {
  const location = useLocation();

  useEffect(() => {
    locationHistory.push(location);
  }, [location]);

  return null;
}
