import MDBox from "components/MDBox";
import LoadingIcon from "components/LoadingIcon";

import DashboardSection from "../DashboardSection";

import OrderNotificationForm from "./OrderNotificationForm";
import useApi from "libs/useApi";
import orderNotifications from "services/core/orderNotifications";

export default function OrderNotification() {
  const { data, refresh } = useApi(orderNotifications.get);

  return (
    <DashboardSection title="Order notifications" description="Get order notifications on WhatsApp">
      {data ? (
        <OrderNotificationForm data={data} onSubmit={refresh} />
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <LoadingIcon />
        </MDBox>
      )}
    </DashboardSection>
  );
}
