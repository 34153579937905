import PhoneIcon from "@mui/icons-material/Phone";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import MDTypography from "components/MDTypography";
import WhatsAppButton from "components/WhatsAppButton";
import EmailButton from "components/EmailButton";

export default function Help() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      rowSpacing={5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      maxWidth="lg"
      mx="auto"
      mt={2}
    >
      <Grid item xs={12} mt={10}>
        <MDTypography variant="h4" gutterBottom>
          {t("We're here to help.")}
        </MDTypography>

        <MDTypography variant="body2">
          {t(
            "Always humans, never bots. For pre-sales questions, existing customers who need a hand, or other inquiries, "
          )}
          <Link to="/support">
            <Box component="span" fontWeight="bold">
              {t("contact us")}
            </Box>
          </Link>
          {t(" and ")}
          <Box component="span" fontWeight="bold">
            {t("we’ll get back to you within an hour.")}
          </Box>
        </MDTypography>
      </Grid>

      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        {/*<MDButton
          href="tel:+85291443907"
          target="_blank"
          variant="text"
          size="large"
          color="dark"
          onClick={() => {
            // @ts-ignore: Google Ads Conversion
            gtag("event", "conversion", {
              send_to: "AW-965382839/Z9kTCKaf46YZELelqswD",
            });
          }}
          startIcon={<PhoneIcon />}
          sx={{ textTransform: "none", padding: 0 }}
        >
          {t("Tel")}
          {t(":")} +852 9144 3907
        </MDButton>*/}

        <WhatsAppButton color="dark" />

        <EmailButton color="dark" />
      </Grid>
    </Grid>
  );
}
