import Switch from "@mui/material/Switch";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import SaveButton from "routes/u/Dashboard/SaveButton";

import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import siteApi, { TSiteRequest } from "services/core/site";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export default function SiteForm({
  data,
  onSubmit,
}: {
  data: TSiteRequest;
  onSubmit: () => void;
}) {
  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
    control,
    reset,
  } = useForm({
    defaultValues: {
      enabled: data.enabled,
    },
  });

  const handleSave = handleSubmit(async (data) => {
    // send
    await siteApi.post(data);
    await onSubmit();
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset(data);
  }, [data, isSubmitSuccessful, reset]);

  return (
    <MDBox>
      <MDBox display="flex" flexDirection="row">
        <PlayArrowIcon fontSize="large" />

        <MDBox
          display="flex"
          flex={1}
          ml={2}
          alignItems="flex-start"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box flex={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Enable/Disable Platform
            </MDTypography>
            <Box display="flex" alignItems="flex-end">
              <MDTypography variant="button" color="text">
                Enable or disable both the website and mobile app. Disabling the
                platform will temporarily make it unavailable for users to make
                purchases.
              </MDTypography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            width={116}
            mt={{ xs: 1, md: 0 }}
            ml={{ xs: 0, md: 1 }}
          >
            <Controller
              name="enabled"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <>
                  <MDBox lineHeight={0} flex={1}>
                    <MDTypography variant="button" color="text">
                      {value ? "Enabled" : "Disabled"}
                    </MDTypography>
                  </MDBox>
                  <Switch
                    checked={value}
                    onBlur={onBlur}
                    onChange={async (e, checked) => onChange(checked)}
                  />
                </>
              )}
            />
          </Box>
        </MDBox>
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </MDBox>
  );
}
