import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";

import LoadingIcon from "components/LoadingIcon";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import config from "config";

import supabase from "libs/supabase";

import { useTranslation } from "react-i18next";

export default function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const handleSignIn = handleSubmit(async ({ email, password }) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setErrorMsg(error.message);
      setOpenErrorAlert(true);
      console.error(error);
      return;
    }

    const requestedLocation = (
      location.state as { requestedLocation?: Location }
    )?.requestedLocation;

    if (requestedLocation) {
      navigate(`${requestedLocation.pathname}${requestedLocation.search}`, {
        replace: true,
      });
      return;
    }

    navigate("/u");
  });

  return (
    <Grid
      p={1}
      pt={11}
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} sm={8} md={6}>
        <Snackbar
          open={openErrorAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setOpenErrorAlert(false)}
        >
          <Alert
            onClose={() => setOpenErrorAlert(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardContent>
            <form onSubmit={handleSignIn}>
              <Stack spacing={1}>
                <MDTypography variant="h3" gutterBottom textAlign="center">
                  {`${t("Sign in to")} ${config.name}`}
                </MDTypography>
                <TextField
                  label={t("Email")}
                  variant="standard"
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />
                <TextField
                  label={t("Password")}
                  variant="standard"
                  type="password"
                  {...register("password", {
                    required: "This field is required",
                  })}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                />

                <MDTypography
                  variant="body2"
                  gutterBottom
                  textAlign="center"
                  pt={3}
                >
                  <MDButton component={Link} to="/forgot-password">
                    {t("Forgot your password?")}
                  </MDButton>
                </MDTypography>
              </Stack>

              <CardActions>
                <MDButton
                  variant="gradient"
                  color="dark"
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <LoadingIcon sx={{ marginRight: 1 }} size={20} />
                  )}
                  {t("Sign In")}
                </MDButton>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
