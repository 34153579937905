import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SaveButton from "routes/u/Dashboard/SaveButton";
import { TCustomRequest } from "services/core/paymentGateways";
import { Box } from "@mui/material";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import { useRichTextEditors } from "components/RichTextEditor/useRichTextEditors";

export default function Custom({
  values,
  onSubmit,
}: {
  values: TCustomRequest;
  onSubmit: (values: TCustomRequest) => void;
}) {
  const { uploadImages } = useRichTextEditors();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      payments: values.payments,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "payments",
  });

  const handleSave = handleSubmit(async () => {
    try {
      await uploadImages();

      const { payments } = getValues();

      await onSubmit({ ...values, payments });
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset({ payments: values.payments });
  }, [values.payments, isSubmitSuccessful, reset]);

  return (
    <Stack mt={2} spacing={2}>
      {fields.map((field, index) => (
        <Box key={field.id}>
          <TextField
            margin="dense"
            label="Payment name"
            {...register(`payments.${index}.title`, {
              required: "This field is required",
            })}
            disabled={isSubmitting}
            error={!!errors.payments?.[index]?.title}
            helperText={errors.payments?.[index]?.title?.message}
            fullWidth
          />

          <Box mb={1}>
            <Controller
              control={control}
              render={({ field }) => (
                <RichTextEditor
                  label="Account name, account number, instructions, etc."
                  editable={!isSubmitting}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                />
              )}
              name={`payments.${index}.description`}
            />
          </Box>

          <MDButton
            onClick={() => remove(index)}
            variant="outlined"
            color="warning"
            size="small"
            startIcon={<DeleteIcon />}
            sx={{ lineHeight: 1.5 }}
            disabled={isSubmitting}
          >
            Delete
          </MDButton>
        </Box>
      ))}

      <MDButton
        onClick={() => append({ title: "", description: "" })}
        variant="outlined"
        color="dark"
        size="small"
        disabled={isSubmitting}
      >
        + Add custom payment
      </MDButton>

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </Stack>
  );
}
