import Box from "@mui/material/Box";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";

import {
  MenuControlsContainer,
  MenuButtonImageUpload,
  RichTextEditor as TiptapRichTextEditor,
  ResizableImage,
  MenuButtonBold,
  MenuButtonItalic,
  MenuDivider,
  MenuButtonTextColor,
} from "mui-tiptap";
import { useEffect, useRef } from "react";
import { TImageSizeKey } from "services/types";
import media from "services/wp/media";
import RichTextEditorLabel from "./RichTextEditorLabel";
import { useRichTextEditors } from "./useRichTextEditors";

export default function RichTextEditor({
  label,
  editable,
  value,
  onBlur,
  onChange,
}: {
  label?: string;
  editable?: boolean;
  value: string;
  onBlur: () => void;
  onChange: (v: string) => void;
}) {
  const objectUrlToFileRef = useRef<{ [key: string]: File }>({});

  const { addRichTextEditor } = useRichTextEditors();

  useEffect(() => {
    return addRichTextEditor(async () => {
      let newValue = value;

      await Promise.all(
        Object.entries(objectUrlToFileRef.current).map(
          async ([objectUrl, file]) => {
            // free memory
            URL.revokeObjectURL(objectUrl);

            // check image deleted or not
            if (!value.includes(objectUrl)) return;

            // Upload image
            const formData = new FormData();
            formData.append("file", file);

            const json = await media.post(formData);

            // Get url
            const sizes: TImageSizeKey[] = [
              "1536x1536",
              "large",
              "woocommerce_single",
            ];

            const size = sizes.find(
              (size) => json.media_details?.sizes?.[size]?.file
            );

            const url =
              size && json.media_details?.sizes?.[size]?.file
                ? json.source_url.replace(
                    /[^/]+$/,
                    json.media_details.sizes?.[size]?.file ?? ""
                  )
                : json.source_url;

            // Preload uploaded image
            await new Promise((r) => {
              const img = new Image();
              img.src = url;
              img.onload = r;
            });

            // Replace description
            newValue = newValue.replaceAll(objectUrl, url);
          }
        )
      );

      onChange(newValue);
    });
  }, [addRichTextEditor, onChange, value]);

  return (
    <Box
      sx={{
        "& p": {
          fontSize: "0.875rem",
        },
        "& .MuiTiptap-MenuBar-sticky": {
          background: "transparent",
        },
        "& .MuiTiptap-FieldContainer-notchedOutline": {
          display: "none",
        },
      }}
      mt={1}
      mb={0.5}
      position="relative"
      lineHeight="normal"
    >
      <RichTextEditorLabel label={label} />

      <TiptapRichTextEditor
        extensions={[StarterKit, Color, TextStyle, ResizableImage]}
        content={value}
        onBlur={onBlur}
        onUpdate={({ editor }) =>
          onChange(editor.isEmpty ? "" : editor.getHTML())
        }
        editable={editable}
        renderControls={() => (
          <MenuControlsContainer>
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonTextColor
              defaultTextColor="#ff0000"
              swatchColors={[
                { value: "#000000", label: "Black" },
                { value: "#ffffff", label: "White" },
                { value: "#888888", label: "Grey" },
                { value: "#ff0000", label: "Red" },
                { value: "#ff9900", label: "Orange" },
                { value: "#ffff00", label: "Yellow" },
                { value: "#00d000", label: "Green" },
                { value: "#0000ff", label: "Blue" },
              ]}
            />
            <MenuDivider />
            <MenuButtonImageUpload
              inputProps={{
                onClick: (e) => (e.currentTarget.value = ""),
              }}
              onUploadFiles={(files) =>
                files.map((file) => {
                  const src = URL.createObjectURL(file);

                  if (objectUrlToFileRef)
                    objectUrlToFileRef.current[src] = file;

                  return { src, width: 512 };
                })
              }
            />
          </MenuControlsContainer>
        )}
      />
    </Box>
  );
}
