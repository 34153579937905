import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PageBody from "components/PageBody";
import PageHeader from "components/PageHeader";

import Customers from "routes/Home/Customers/Customers";
import Features from "routes/Home/Features/Features";
import Help from "routes/Home/Help/Help";
import Intro from "routes/Home/Intro/Intro";
import Faqs from "routes/Home/Faqs/Faqs";
import PricingSection from "routes/Home/PricingSection/PricingSection";
import Projects from "routes/Home/Projects/Projects";

import bg from "./bg.jpg";
import config from "config";

import { useTranslation } from "react-i18next";

export default function Home() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${config.name} | 開發手機App公司 | App Development Company`;
  }, []);

  return (
    <>
      <PageHeader
        minHeight="75vh"
        alpha={0.8}
        bgImage={bg}
        videoId="iJQHPRzJj20"
      >
        <Container>
          <Grid
            container
            px={{ xs: 0, md: 0 }}
            rowSpacing={5}
            display="flex"
            justifyContent="center"
            maxWidth="lg"
            m="auto"
            color="#fff"
          >
            <Grid
              item
              xs={12}
              px={0}
              justifyContent="center"
              textAlign="center"
            >
              <MDTypography
                variant="h1"
                fontSize={{
                  xs: "2.0rem",
                  sm: "2.6rem",
                  lg: "2.8rem",
                }}
                textAlign="center"
                gutterBottom
                color="light"
              >
                {i18n.language === "zh-Hant-HK" ? (
                  <>
                    <Box component="span" display="inline-block">
                      開發手機APP
                    </Box>
                  </>
                ) : (
                  <>
                    <Box component="span" display="inline-block">
                      Develop Mobile App
                    </Box>
                  </>
                )}
              </MDTypography>

              <MDTypography
                textAlign="center"
                variant="subtitle1"
                gutterBottom
                color="light"
              >
                {t(
                  "Online store, car rental, food delivery, booking apps, etc."
                )}
              </MDTypography>

              <MDButton
                sx={{
                  mt: { xs: 3, sm: 5 },
                }}
                variant="gradient"
                size="large"
                onClick={() => {
                  navigate("/#pricing");
                  setTimeout(
                    () => document.getElementById("pricing")?.scrollIntoView(),
                    100
                  );
                  // @ts-ignore: Event snippet for eShopRocket Action Button Clicked conversion page
                  gtag("event", "conversion", {
                    send_to: "AW-965382839/cWHoCLzPiagZELelqswD",
                  });
                }}
                color="info"
              >
                <MDBox>
                  <MDTypography
                    variant="h5"
                    textAlign="center"
                    textTransform="uppercase"
                    color="light"
                  >
                    {t("Learn More")}
                  </MDTypography>

                  <MDTypography
                    variant="button"
                    textAlign="center"
                    color="light"
                  >
                    {t("Mobile App (iOS + Android) & Website")}
                  </MDTypography>
                </MDBox>
              </MDButton>
            </Grid>
          </Grid>
        </Container>
      </PageHeader>

      <PageBody>
        <Container>
          <Box sx={{ p: 2 }}>
            <Intro />
            <Divider />
            <Features />
            <Divider />
            <Projects />
            <Divider />
            <Customers />
          </Box>
        </Container>

        <PricingSection />

        <Container>
          <Box sx={{ p: 2 }}>
            <Faqs />
            <Help />
          </Box>
        </Container>
      </PageBody>
    </>
  );
}
