import MDBox from "components/MDBox";
import ShadowImage from "components/ShadowImage";
import images from "libs/images";
import { useEffect, useState } from "react";
import media from "services/wp/media";
import LoadingIcon from "components/LoadingIcon";

export default function UploadingImage({
  image,
  height,
  onCompleted,
}: {
  image: File;
  height: number;
  onCompleted: (id?: number) => void;
}) {
  const [src, setSrc] = useState<string | null>();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const src = URL.createObjectURL(image);
    setSrc(src);

    (async () => {
      const formData = new FormData();
      formData.append("file", image);
      try {
        const json = await media.post(formData);

        images.add({
          [json.id]: {
            id: json.id,
            src: json.source_url,
            metadata: json.media_details,
            blurhash: "",
            _file: image,
          },
        });

        onCompleted(json.id);
      } catch (error) {
        onCompleted();
      } finally {
        setCompleted(true);
      }
    })();

    return () => {
      URL.revokeObjectURL(src);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!src) return null;

  return (
    <MDBox p={2} position="relative" alignSelf="flex-start">
      <ShadowImage src={src} height={height} opacity={completed ? 1 : 0.5} />
      <MDBox
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingIcon />
      </MDBox>
    </MDBox>
  );
}
