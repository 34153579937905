import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Collapse from "@mui/material/Collapse";
import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function Faq({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <MDBox mb={2}>
      <MDBox
        onClick={() => setOpen((open) => !open)}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        sx={{
          cursor: "pointer",
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <MDTypography
          variant="h5"
          color={open ? "dark" : "text"}
          sx={{ userSelect: "none" }}
        >
          {title}
        </MDTypography>
        <MDBox color={open ? "dark" : "text"}>
          {open ? (
            <RemoveIcon sx={{ fontWeight: "bold" }} fontSize="small" />
          ) : (
            <AddIcon sx={{ fontWeight: "bold" }} fontSize="small" />
          )}
        </MDBox>
      </MDBox>
      <Collapse timeout={400} in={open}>
        <MDBox py={2} lineHeight={1}>
          <MDTypography
            variant="body2"
            color="text"
            opacity={0.8}
            fontWeight="regular"
          >
            {children}
          </MDTypography>
        </MDBox>
      </Collapse>
    </MDBox>
  );
}
