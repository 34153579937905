import { useCallback, useEffect, useState } from "react";

export default function useApi<TParams, TResponseData>(
  api: (params?: TParams) => Promise<TResponseData>,
  params?: TParams,
  preload: boolean = true
) {
  const [data, setData] = useState<TResponseData>();

  const [loading, setLoading] = useState(preload);

  const refresh = useCallback(async () => {
    setLoading(true);

    const data = await api(params);

    setData(data);

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data,
    setData,
    loading,
    refresh,
  };
}
