import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PanToolIcon from "@mui/icons-material/PanTool";

import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import { useState } from "react";

import MDBox from "components/MDBox";
import LoadingIcon from "components/LoadingIcon";

import Custom from "routes/u/Dashboard/PaymentGateways/Custom";
import Cod from "routes/u/Dashboard/PaymentGateways/Cod";
import Bacs from "routes/u/Dashboard/PaymentGateways/Bacs";
import PaymentGateway from "routes/u/Dashboard/PaymentGateways/PaymentGateway";
import Stripe from "routes/u/Dashboard/PaymentGateways/Stripe";
import DashboardSection from "routes/u/Dashboard/DashboardSection";

import paymentGatewaysApi, {
  TPaymentGatewaysResponse,
} from "services/core/paymentGateways";
import useApi from "libs/useApi";

export default function PaymentGateways() {
  const [errorMsg, setErrorMsg] = useState<string>("");

  const {
    data: paymentGateways,
    setData: setPaymentGateways,
    refresh,
  } = useApi(paymentGatewaysApi.get);

  const register = <TKey extends keyof TPaymentGatewaysResponse>(id: TKey) => {
    const validate = (values: TPaymentGatewaysResponse[TKey]) => {
      if (
        !Object.values({ ...paymentGateways, [id]: values }).find(
          ({ enabled }) => enabled
        )
      ) {
        setErrorMsg("Oops, you must enable at least one payment method");

        return false;
      }

      return true;
    };

    return {
      values: paymentGateways?.[id],

      onValuesChange: (values: TPaymentGatewaysResponse[TKey]) => {
        // validate
        if (!validate(values)) return;

        setPaymentGateways((paymentGateways) => {
          if (!paymentGateways) return paymentGateways;

          return {
            ...paymentGateways,
            [id]: values,
          };
        });
      },

      onSubmit: async (values: TPaymentGatewaysResponse[TKey]) => {
        // validate
        if (!validate(values)) return;

        // send

        await paymentGatewaysApi.post(id, values);
        await refresh();
      },
    };
  };

  return (
    <DashboardSection
      title="Payments"
      description="Set your payment methods and receive them as payouts"
    >
      {!!errorMsg && (
        <Alert
          onClose={() => setErrorMsg("")}
          severity="error"
          sx={{ width: "100%", mb: 4 }}
        >
          {errorMsg}
        </Alert>
      )}
      {paymentGateways ? (
        <>
          <PaymentGateway
            Icon={CreditCardIcon}
            title="Credit/Debit Card"
            description={
              <>
                {
                  "Accept card payments to boost sales - over 70% of buyers prefer card payments. Note: "
                }
                <Link
                  href="https://stripe.com/pricing"
                  color="#000"
                  target="_blank"
                >
                  Card fees
                </Link>
                {" apply."}
              </>
            }
            {...register("stripe")}
            ContentComponent={Stripe}
          />

          <PaymentGateway
            Icon={AccountBalanceIcon}
            title="Bank Transfer"
            description="Accept bank transfers via manual process"
            {...register("bacs")}
            validate={(values) =>
              !values.enabled ||
              !!(values.enabled && values.title && values.description)
            }
            ContentComponent={Bacs}
          />

          <PaymentGateway
            Icon={AttachMoneyIcon}
            title="Cash"
            description="Accept cash upon delivery on pickup"
            {...register("cod")}
            ContentComponent={Cod}
          />

          <PaymentGateway
            Icon={PanToolIcon}
            title="Custom Payments"
            description="Manually review and approve custom payment method transactions."
            {...register("custom")}
            validate={(values) =>
              !values.enabled ||
              !!(
                values.enabled &&
                values.payments.length &&
                !values.payments.find(
                  ({ title, description }) => !title || !description
                )
              )
            }
            ContentComponent={Custom}
          />
        </>
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <LoadingIcon />
        </MDBox>
      )}
    </DashboardSection>
  );
}
