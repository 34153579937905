import api from "services/core/_api";

export type TCodRequest = {
  enabled: boolean;
  description: string;
};

export type TStripeRequest = {
  enabled: boolean;
};

export type TCustomRequest = {
  enabled: boolean;
  payments: {
    title: string;
    description: string;
  }[];
};

export type TBacsRequest = {
  enabled: boolean;
  title: string;
  description: string;
};

export type TPaymentGatewaysResponse = {
  cod: {
    enabled: boolean;
    description: string;
  };
  stripe: {
    enabled: boolean;
  };
  custom: {
    enabled: boolean;
    payments: {
      title: string;
      description: string;
    }[];
  };
  bacs: {
    enabled: boolean;
    title: string;
    description: string;
  };
};

const paymentGateways = {
  get: async (): Promise<TPaymentGatewaysResponse> =>
    (await api.get("admin/payment_gateways")).data,

  post: async (
    id: string,
    data: TCodRequest | TStripeRequest | TCustomRequest | TBacsRequest
  ): Promise<{}> => (await api.post(`admin/payment_gateways/${id}`, data)).data,
};

export default paymentGateways;
