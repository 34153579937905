import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import LoadingIcon from "components/LoadingIcon";
import MDButton from "components/MDButton";
import PriceCard from "components/PriceCard/PriceCard";

import { useNavigate } from "react-router-dom";
import config from "config";

import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { emailHtml } from "components/LocationHistory";

type InputData = {
  placeholder: string;
  required?: boolean;
  multiline?: boolean;
  type?: string;
};

const inputs: InputData[] = [
  {
    required: true,
    placeholder: "Full Name",
  },
  {
    required: true,
    type: "email",
    placeholder: "Email",
  },
  {
    required: true,
    placeholder: "Phone Number",
  },
  {
    placeholder: "Your Message (Optional)",
    multiline: true,
  },
];

export default function RequestDemoDialog({
  open,
  onClose,
  gold,
}: {
  open: boolean;
  onClose: () => void;
  gold?: boolean;
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleRequestDemo = handleSubmit(async (data) => {
    const formData = new FormData();

    let msg = "";

    inputs.forEach(({ placeholder }, i) => {
      const name = `field${i}`;
      msg += `<b>${t(placeholder)}</b><br />`;
      msg += data[name];
      msg += "<br /><br />";
    });

    msg += gold ? "Gold Plan" : "White Plan";

    msg += "<br /><br />";

    msg += emailHtml();

    formData.append("msg", msg);

    await fetch(`${config.functionUrl}/email/send`, {
      method: "post",
      body: formData,
    });

    // @ts-ignore: Google Ads Conversion
    gtag("event", "conversion", {
      send_to: "AW-965382839/npw5CIe14KYZELelqswD",
    });

    navigate("/support/thanks");
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        sx: {
          backgroundColor: "rgb(17, 19, 34)",
        },
        onSubmit: handleRequestDemo,
      }}
    >
      <DialogTitle fontStyle={{ color: "#fff" }}>
        {t("Schedule Your Demo")}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1}>
          <PriceCard gold={gold} />

          {inputs.map(({ placeholder, multiline, type, required }, i) => {
            const name = `field${i}`;
            return (
              <TextField
                key={name}
                sx={{
                  "& label": {
                    color: "#fff",
                  },
                  "& label.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#fff",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#fff",
                    },
                    "&:hover fieldset": {
                      borderColor: "#fff",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#fff",
                    },
                  },
                  color: "#fff",
                }}
                inputProps={{
                  sx: {
                    color: "#fff",
                  },
                }}
                type={type}
                multiline={multiline}
                fullWidth
                error={!!errors[name]}
                helperText={errors[name]?.message}
                placeholder={t(placeholder)}
                minRows={multiline ? 2 : undefined}
                {...register(name, { required })}
              />
            );
          })}
        </Box>
      </DialogContent>

      <DialogActions>
        <MDButton
          type="submit"
          variant="contained"
          color="warning"
          fullWidth
          sx={{
            backgroundColor: "rgb(255, 190, 99)",
            color: "rgb(2, 6, 23)",
            ":hover": {
              backgroundColor: "rgb(255, 190, 99)",
              color: "rgb(2, 6, 23)",
            },
          }}
        >
          {isSubmitting && <LoadingIcon sx={{ marginRight: 1 }} size={20} />}
          {t("Try it for free")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
