import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import SaveButton from "routes/u/Dashboard/SaveButton";

import checkoutFields, {
  TCheckoutFieldsResponse,
} from "services/core/checkoutFields";

import "react-phone-number-input/style.css";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import { useRichTextEditors } from "components/RichTextEditor/useRichTextEditors";

export default function CheckoutFieldsForm({
  data,
  onSubmit,
}: {
  data: TCheckoutFieldsResponse;
  onSubmit: () => void;
}) {
  const { uploadImages } = useRichTextEditors();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({ defaultValues: data });

  const handleSave = handleSubmit(async () => {
    await uploadImages();

    const data = await getValues();

    // send
    await checkoutFields.post(data);
    await onSubmit();
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset(data);
  }, [data, isSubmitSuccessful, reset]);

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <CalendarMonthIcon fontSize="large" />

        <Box
          display="flex"
          flex={1}
          ml={2}
          alignItems="flex-start"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box flex={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Delivery Date
            </MDTypography>
          </Box>

          <Controller
            control={control}
            render={({ field }) => (
              <MDBox
                display="flex"
                alignItems="center"
                width={116}
                mt={{ xs: 1, md: 0 }}
                ml={{ xs: 0, md: 1 }}
              >
                <MDBox lineHeight={0} flex={1}>
                  <MDTypography variant="button" color="text">
                    {field.value ? "Enabled" : "Disabled"}
                  </MDTypography>
                </MDBox>
                <Switch
                  checked={field.value}
                  disabled={isSubmitting}
                  onBlur={field.onBlur}
                  onChange={async (e, checked) => field.onChange(checked)}
                />
              </MDBox>
            )}
            name="delivery_date.enabled"
          />
        </Box>
      </Box>

      <Box ml={2} mt={2} mb={1} pl={4}>
        <Controller
          control={control}
          render={({ field }) => (
            <RichTextEditor
              label="Description"
              editable={!isSubmitting}
              value={field.value}
              onBlur={field.onBlur}
              onChange={field.onChange}
            />
          )}
          name="delivery_date.description"
        />
      </Box>

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </Box>
  );
}
