import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("Privacy policy")} | ${config.name}`;
  }, [t]);

  return (
    <MDBox component="section" pt={20}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="dark"
                p={3}
                mt={-3}
                mx={2}
              >
                <MDTypography variant="h3" color="white">
                  Privacy & Policy
                </MDTypography>
                <MDTypography variant="body2" color="white" opacity={0.8}>
                  How we handle your data
                </MDTypography>
              </MDBox>
              <MDBox pb={6} px={6}>
                <MDTypography variant="h5" mt={6} mb={3}>
                  Introduction
                </MDTypography>
                <MDTypography variant="body2" color="text">
                  {config.name} is an e-commerce platform for businesses to
                  start an online shop easily. At {config.name}, your privacy is
                  important to us, and we want you to feel confident that your
                  personal information is secure when using our products and our
                  platform.
                  <br />
                  <br />
                  It is {config.name}'s policy to respect your privacy regarding
                  any information we may collect while operating our website.
                </MDTypography>
                <MDTypography variant="h5" mt={6} mb={3}>
                  Website Visitors
                </MDTypography>
                <MDTypography variant="body2" color="text">
                  Like most website operators, {config.name} collects
                  non-personally-identifying information of the sort that web
                  browsers and servers typically make available, such as the
                  browser type, language preference, referring site, and the
                  date and time of each visitor request. {config.name}'s purpose
                  in collecting non-personally identifying information is to
                  better understand how {config.name}'s visitors use its
                  website. From time to time, {config.name} may release
                  non-personally-identifying information in the aggregate, e.g.,
                  by publishing a report on trends in the usage of its website.
                  <br />
                  <br />
                  {config.name} also collects potentially personally-identifying
                  information like Internet Protocol (IP) addresses for logged
                  in users and for users making payments on {config.name}.{" "}
                  {config.name}
                  only discloses logged in user and commenter IP addresses under
                  the same circumstances that it uses and discloses
                  personally-identifying information as described below, except
                  that payee IP addresses and email addresses are visible and
                  disclosed to the administrators of {config.name} and is
                  handled by payment processors at the time of processing the
                  payments.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  Payments
                </MDTypography>

                <MDTypography variant="body2" color="text">
                  To make a payment to a creator on {config.name}, you have to
                  provide our payment processor (Stripe) with your payment
                  information. They provide us with a token that represents your
                  account, your card's expiration date, card type and the last
                  four digits of your credit card. If you provide them with a
                  name and email address then they also provide us with that
                  information.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  Gathering of Personally-Identifying Information
                </MDTypography>
                <MDTypography variant="body2" color="text">
                  Certain visitors to {config.name}'s websites choose to
                  interact with {config.name} in ways that require {config.name}{" "}
                  to gather personally-identifying information. The amount and
                  type of information that {config.name} gathers depends on the
                  nature of the interaction. For example, we ask sellers who
                  sign up on
                  {config.name} to provide an email address and name. Those who
                  engage in transactions with {config.name} are asked to provide
                  additional information, including as necessary the personal
                  and financial information required to process those
                  transactions.
                  <br />
                  <br />
                  In each case, {config.name} collects such information only
                  insofar as is necessary or appropriate to fulfill the purpose
                  of the visitor's interaction with {config.name}. {config.name}{" "}
                  does not disclose personally-identifying information other
                  than as described below. And visitors can always refuse to
                  supply personally-identifying information, with the caveat
                  that it may prevent them from engaging in certain
                  website-related activities.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  Protection of Certain Personally-Identifying Information
                </MDTypography>

                <MDTypography variant="body2" color="text">
                  {config.name} discloses potentially personally-identifying and
                  personally-identifying information only to those of its
                  employees, contractors and affiliated organizations that (i)
                  need to know that information in order to process it on
                  {config.name}'s behalf or to provide services available at
                  {config.name}'s websites, and (ii) that have agreed not to
                  disclose it to others. Some of those employees, contractors
                  and affiliated organizations may be located outside of your
                  home country; by using {config.name}'s websites, you consent
                  to the transfer of such information to them.
                  <br />
                  <br />
                  {config.name} will not rent or sell potentially
                  personally-identifying and personally-identifying information
                  to anyone. Other than to its employees, contractors and
                  affiliated organizations, as described above, {config.name}
                  discloses potentially personally-identifying and
                  personally-identifying information only in response to a
                  subpoena, court order or other governmental request, or when
                  {config.name} believes in good faith that disclosure is
                  reasonably necessary to protect the property or rights of{" "}
                  {config.name}, third parties or the public at large.
                  <br />
                  <br />
                  If you are a registered user of an {config.name} website and
                  have supplied your email address, {config.name} may
                  occasionally send you an email to tell you about new features,
                  solicit your feedback, or just keep you up to date with what's
                  going on with {config.name} and our products. If you send us a
                  request (for example via email or via one of our feedback
                  mechanisms), we reserve the right to publish it in order to
                  help us clarify or respond to your request or to help us
                  support other users.
                  <br />
                  <br />
                  {config.name} takes all measures reasonably necessary to
                  protect against the unauthorized access, use, alteration or
                  destruction of potentially personally-identifying and
                  personally-identifying information.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  Aggregated Statistics
                </MDTypography>

                <MDTypography variant="body2" color="text">
                  {config.name} may collect statistics about the behavior of
                  visitors to its websites. {config.name} may display this
                  information publicly or provide it to others. However,{" "}
                  {config.name} does not disclose personally-identifying
                  information other than as described above.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  Cookies
                </MDTypography>

                <MDTypography variant="body2" color="text">
                  A cookie is a string of information that a website stores on a
                  visitor's computer, and that the visitor's browser provides to
                  the website each time the visitor returns.
                  <br />
                  <br />
                  {config.name} uses cookies to help {config.name} identify and
                  track visitors, their usage of {config.name} website, and
                  their website access preferences. Some cookies expire after a
                  certain amount of time, or upon logging out (session cookies),
                  others remain on your computer or terminal device for a longer
                  period (persistent cookies). Our Site uses first party cookies
                  as well as third party cookies, as described below:
                  <br />
                  <br />
                  Strictly Necessary Cookies: Used to provide Users with the
                  Services available through our Site and to use some of their
                  features, such as the ability to log-in and access to secure
                  areas. These cookies are essential for using and navigating
                  the Site. Without these cookies, basic functions of our Site
                  would not work. Because these cookies are strictly necessary
                  to deliver the Site and the Services, you cannot refuse them.
                  <br />
                  <br />
                  Analytics/Performance: Used to better understand the behavior
                  of the Users on our Site and improve our Site accordingly, for
                  example by making sure Users are finding what they need
                  easily. The Site uses Google Analytics, a web analytics
                  service provided by Google Inc. ("Google"). The information
                  collected by Google (including your IP address) will be
                  transmitted to and stored by Google on servers in the United
                  States (Google is certified to the Privacy Shield for data
                  transfers). How long a Google Analytics cookie remains on your
                  computer or device depends on what it is and what it is used
                  for. Some Google Analytics cookies expire at the end of your
                  browser session, whilst others can remain for up to two years.
                  You can prevent your data from being collected by Google
                  Analytics on our Site by downloading and installing the Google
                  Analytics Opt-out Browser Add-on for your current web browser.
                  <br />
                  <br />
                  {config.name} visitors who do not wish to have cookies placed
                  on their computers should set their browsers to refuse cookies
                  before using {config.name}'s websites, with the drawback that
                  certain features of {config.name}'s websites may not function
                  properly without the aid of cookies.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  International Users
                </MDTypography>

                <MDTypography variant="body2" color="text">
                  If you are accessing our Services from the European Union or
                  other regions with laws governing data collection and use,
                  please note that your Personal Data will be transmitted to our
                  servers in the United States and the data may be transmitted
                  to our service providers supporting our business operations
                  (described above). The United States may have data protection
                  laws less stringent than or otherwise different from the laws
                  in effect in the country in which you are located. Where we
                  transfer your Personal Data out of the EU we will take steps
                  to ensure that your Personal Data receives an adequate level
                  of protection where it is processed and your rights continue
                  to be protected (GDPR).
                  <br />
                  <br />
                  By providing your information to the Services you agree to the
                  transfer of your information to the United States and
                  processing globally in accordance with this Privacy Policy.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  Privacy Policy Changes
                </MDTypography>

                <MDTypography variant="body2" color="text">
                  Although most changes are likely to be minor, {config.name}{" "}
                  may change its Privacy Policy from time to time, and in{" "}
                  {config.name}'s sole discretion. {config.name} encourages
                  visitors to frequently check this page for any changes to its
                  Privacy Policy. If you have a {config.name} account, you might
                  also receive an alert informing you of these changes. Your
                  continued use of this site after any change in this Privacy
                  Policy will constitute your acceptance of such change.
                </MDTypography>

                <MDTypography variant="h5" mt={6} mb={3}>
                  Whew, is that all?
                </MDTypography>

                <MDTypography variant="body2" color="text">
                  By agreeing to our privacy policy you are also agreeing to the
                  Google's privacy policy for the services which we use like
                  Google Sign In, Recaptcha, Analytics, etc. which can be found
                  here: https://policies.google.com/privacy?hl=en-US
                  <br />
                  <br />
                  Please feel free to contact us if you have any questions about
                  {config.name}'s Privacy Policy or the information practices.
                  <br />
                  <br />
                  You may contact us at {config.supportEmail}
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}
