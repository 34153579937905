import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import config from "config";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";

type NavBarButtonProps = {
  title: string;
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "default";
  to?: string;
  href?: string;
  icon?: ReactNode;

  variant?: "text" | "outlined" | "contained" | "gradient";
  onClick?: () => void;

  drawer?: boolean;
};

function NavBarButton({
  to,
  href,
  icon,
  title,
  variant,
  color,
  onClick,
  drawer,
}: NavBarButtonProps) {
  return (
    <MDButton
      sx={{
        textTransform: "none",
        px: 1,
        justifyContent: drawer && variant === "text" ? "flex-start" : "center",
      }}
      color={color}
      variant={variant}
      startIcon={icon}
      fullWidth={drawer}
      onClick={onClick}
      {...(to ? { component: Link, to } : {})}
      {...(href && { component: "a", href, target: "_blank" })}
    >
      {title}
    </MDButton>
  );
}

export default function NavBar({
  leftButtons,
  rightButtons,
}: {
  leftButtons?: NavBarButtonProps[];
  rightButtons?: NavBarButtonProps[];
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const iconsStyle = ({ palette: { dark } }: { palette: any }) => ({
    color: () => {
      return dark.main;
    },
  });

  return (
    <Container sx={{ position: "sticky", top: 0, zIndex: 10 }}>
      <MDBox
        sx={{
          backdropFilter: "saturate(200%) blur(30px)",
        }}
        position="absolute"
        color="transparent"
        mx={3}
        my={2}
        px={0}
        py={{ xs: 0.5, sm: 0 }}
        width="calc(100% - 48px)"
        borderRadius="0.75rem"
        left={0}
        zIndex={100}
        shadow="lg"
        bgColor="rgba(255,255,255,0.8)"
      >
        <Toolbar>
          <Box mr={2} flex={{ xs: 1, md: 0 }} display="flex">
            <Link to="/">
              <MDTypography
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                variant="h5"
                component="span"
              >
                {config.name}
              </MDTypography>
            </Link>
          </Box>
          <Box flex={1} display={{ xs: "none", md: "flex" }}>
            <Stack direction="row" spacing={0.5}>
              {leftButtons?.map((props) => (
                <NavBarButton key={props.title} {...props} />
              ))}
            </Stack>
          </Box>
          <Box display={{ xs: "none", md: "flex" }}>
            <Stack direction="row" spacing={0.5}>
              {rightButtons?.map((props) => (
                <NavBarButton key={props.title} {...props} />
              ))}
            </Stack>
          </Box>
          <MDBox display={{ xs: "flex", md: "none" }}>
            <IconButton edge="end" onClick={() => setDrawerOpen(true)}>
              <Menu sx={iconsStyle} />
            </IconButton>
            <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <List>
                <ListItem>
                  <ListItemButton
                    to="/"
                    component={Link}
                    color="black"
                    sx={{ textDecoration: "none" }}
                    onClick={() => setDrawerOpen(false)}
                  >
                    <ListItemText
                      primary={config.name}
                      primaryTypographyProps={{
                        variant: "h4",
                        component: "span",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
                {leftButtons?.map((props) => (
                  <ListItem key={props.title} sx={{ px: 1, mb: 1 }}>
                    <NavBarButton {...props} drawer />
                  </ListItem>
                ))}
                {!!rightButtons?.length && !!leftButtons?.length && <Divider />}
                {rightButtons?.map((props) => (
                  <ListItem key={props.title} sx={{ px: 1, mb: 1 }}>
                    <NavBarButton
                      {...props}
                      onClick={() => {
                        props.onClick?.();
                        setDrawerOpen(false);
                      }}
                      drawer
                    />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </MDBox>
        </Toolbar>
      </MDBox>
    </Container>
  );
}
