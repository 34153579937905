import { DependencyList, useCallback, useEffect, useState } from "react";

export default function useAsync<T>(
  cached: () => Promise<T>,
  deps: DependencyList
) {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<T | null>(null);

  const refresh = useCallback(async () => {
    setLoading(true);
    setValue(await cached());
    setLoading(false);
  }, [cached]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { value, loading, refresh };
}
