import MDBox from "components/MDBox";
import LoadingIcon from "components/LoadingIcon";

import DashboardSection from "../DashboardSection";
import Layouts from "./Layouts";
import layouts from "services/core/layouts";
import useApi from "libs/useApi";

export default function Appearance() {
  const { data: layout, refresh } = useApi(layouts.get);

  return (
    <DashboardSection
      title="Appearance"
      description="Make changes to your site’s appearance"
    >
      {layout ? (
        <Layouts layout={layout} onSubmit={refresh} />
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <LoadingIcon />
        </MDBox>
      )}
    </DashboardSection>
  );
}
