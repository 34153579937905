import api from "services/wc/_api";

export type TFlatRate = {
  id: number;
  title: string;
  method_id: "flat_rate";
  settings: {
    title: {
      value: string;
    };
    cost: {
      value: string;
    };
  };
};

export type TFreeShipping = {
  id: number;
  title: string;
  method_id: "free_shipping";
  settings: {
    title: {
      value: string;
    };
    min_amount: {
      value: string;
    };
  };
};

export type TFlexibleShippingSingle = {
  id: number;
  title: string;
  method_id: "flexible_shipping_single";
  settings: {
    method_title: {
      value: string;
    };
    method_description: {
      value: string;
    };
    method_rules: {
      value: {
        conditions: {
          condition_id: "value";
          min: string;
          max: string;
        }[];
        cost_per_order: string;
        additional_costs: [];
        special_action: "";
      }[];
    };
  };
};

export type TShippingZoneMethod =
  | TFlexibleShippingSingle
  | TFlatRate
  | TFreeShipping;

export type TShippingZoneMethodsResponse = TShippingZoneMethod[];

const shippingZoneMethods = {
  get: async (zone: number): Promise<TShippingZoneMethodsResponse> =>
    (await api.get(`/shipping/zones/${zone}/methods`)).data,

  put: async (
    zone: number,
    id: number,
    data: {
      order: number;
      settings: {
        method_title?: string;
        method_description?: string;
        method_rules?: string;
      };
    }
  ): Promise<{}> =>
    (await api.put(`/shipping/zones/${zone}/methods/${id}`, data)).data,

  post: async (
    zone: number,
    data: {
      order: number;
      method_id: string;
      settings: {
        method_title?: string;
        method_description?: string;
        method_rules?: string;
      };
    }
  ): Promise<{}> =>
    (await api.post(`/shipping/zones/${zone}/methods`, data)).data,

  delete: async (zone: number, id: number): Promise<{}> =>
    (await api.delete(`/shipping/zones/${zone}/methods/${id}?force=true`)).data,
};

export default shippingZoneMethods;
