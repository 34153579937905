import Store from "@mui/icons-material/Store";
import PointOfSale from "@mui/icons-material/PointOfSale";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { CardActionArea } from "@mui/material";

import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import config from "config";
import Appearance from "routes/u/Dashboard/Appearance/Appearance";
import PaymentGateways from "routes/u/Dashboard/PaymentGateways/PaymentGateways";
import Settings from "routes/u/Dashboard/Settings/Settings";
import Loading from "routes/Loading/Loading";
import Shipping from "routes/u/Dashboard/Shipping/Shipping";
import { getSite } from "libs/sites";
import OrderNotification from "./OrderNotification/OrderNotification";
import CheckoutFields from "./CheckoutFields/CheckoutFields";
import Site from "./Site/Site";

export default function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  const [subdomain, setSubdomain] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const site = await getSite();
      if (!site) return;

      setSubdomain(site.subdomain);
    })();
  }, []);

  if (!subdomain) return <Loading />;

  const buttons = [
    {
      href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}`,
      title: "Your Store",
      Icon: Store,
      description: `${config.wpProtocol}//${subdomain}.${config.wpDomain}`,
      target: "_blank",
    },
    {
      href: `${config.wpProtocol}//${subdomain}.${config.wpDomain}/wp-admin`,
      title: "Store Admin",
      Icon: PointOfSale,
      description: "Pages, Customers, Marketing...",
      target: "_blank",
    },
  ];

  return (
    <MDBox
      component="section"
      pt={{
        xs: 16,
        md: 18,
      }}
    >
      <Container sx={{ mb: 8 }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {buttons.map(({ href, title, Icon, description, target }, i) => (
            <Grid key={`button-${i}`} item xs={12} md={6} lg={4}>
              <Card>
                <CardActionArea href={href} target={target}>
                  <MDBox
                    pt={3}
                    pb={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <MDBox
                      width="4rem"
                      height="4rem"
                      bgColor="dark"
                      variant="gradient"
                      coloredShadow="dark"
                      borderRadius="xl"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                    >
                      <Icon fontSize="medium" />
                    </MDBox>

                    <MDTypography gutterBottom variant="h5" mt={2}>
                      {title}
                    </MDTypography>

                    <MDTypography
                      gutterBottom
                      variant="button"
                      color="rgb(123, 128, 154)"
                    >
                      {description}
                    </MDTypography>
                  </MDBox>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Stack spacing={3}>
        <Settings />

        <Site />

        <Appearance />

        <Shipping />

        <CheckoutFields />

        <OrderNotification />

        <PaymentGateways />
      </Stack>
    </MDBox>
  );
}
