import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { SxProps, Theme } from "@mui/material";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";

export default function WhatsAppButton({
  color,
  sx,
}: {
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "default";
  sx?: SxProps<Theme>;
}) {
  const { t } = useTranslation();

  return (
    <MDButton
      href="https://wa.me/message/DSLGB5GUU2WFH1"
      target="_blank"
      variant="text"
      size="large"
      color={color}
      onClick={() => {
        // @ts-ignore: Google Ads Conversion
        gtag("event", "conversion", {
          send_to: "AW-965382839/uzO1CM2T46YZELelqswD",
        });
      }}
      startIcon={<WhatsAppIcon />}
      sx={{ textTransform: "none", padding: 0, ...sx }}
    >
      WhatsApp{t(":")} +852 9144 3907
    </MDButton>
  );
}
