import api from "services/core/_api";

export type TStripeAccountUrlResponse = {
  url: string;
};

export type TStripeCheckResponse = {
  finished: boolean;
};

const stripe = {
  accountUrl: async (restart: boolean): Promise<TStripeAccountUrlResponse> =>
    (await api.get(`admin/stripe/account_url?restart=${restart}`)).data,

  check: async (): Promise<TStripeCheckResponse> =>
    (await api.get(`admin/stripe/check`)).data,
};

export default stripe;
