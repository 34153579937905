import { useMemo } from "react";
import images from "libs/images";
import { TImage } from "services/types";
import WPImage from "./WPImage";

export default function FixHeightWPImage({
  id,
  height,
  size,
}: {
  id: number;
  height: number;
  size: keyof TImage["metadata"]["sizes"];
}) {
  const width = useMemo(() => {
    const image = images.get(id);

    if (!image) return null;

    return Math.round((image.metadata.width * height) / image.metadata.height);
  }, [id, height]);

  if (!width) return null;

  return <WPImage id={id} size={size} width={width} height={height} />;
}
