import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import pRetry from "p-retry";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingIcon from "components/LoadingIcon";

import config from "config";
import supabase from "libs/supabase";

declare global {
  interface Window {
    fbq: any;
  }
}

export default function SignUp() {
  const navigate = useNavigate();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const defaultValues = {
    email: "",
    password: "",
    shopName: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const handleSignUp = handleSubmit(async ({ email, password, shopName }) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error) {
      setErrorMsg(error.message);
      setOpenErrorAlert(true);
      console.error(error);
      return;
    }

    const accessToken = data.session?.access_token;

    const res = await pRetry(
      () =>
        fetch(`${config.wpProtocol}//api.${config.wpDomain}/create`, {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ subdomain: shopName.toLowerCase() }),
        }),
      { retries: 3 }
    );
    const json = await res.json();

    if (res.status !== 200) {
      setErrorMsg(json.message);
      setOpenErrorAlert(true);
      console.error(json);
      throw Error("Create Shop Failed!");
    }

    navigate("/u");
  });

  return (
    <Grid
      p={1}
      pt={11}
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} sm={8} md={6}>
        <Snackbar
          open={openErrorAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setOpenErrorAlert(false)}
        >
          <Alert
            onClose={() => setOpenErrorAlert(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardContent>
            <form onSubmit={handleSignUp}>
              <Stack spacing={1}>
                <MDTypography variant="h3" gutterBottom textAlign="center">
                  {`Try ${config.name} free`}
                </MDTypography>
                <TextField
                  label="Email"
                  variant="standard"
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />
                <TextField
                  label="Password"
                  variant="standard"
                  type="password"
                  {...register("password", {
                    required: "This field is required",
                    minLength: {
                      value: 6,
                      message: "Password should be at least 6 characters",
                    },
                  })}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                />
                <TextField
                  label="Your store name"
                  variant="standard"
                  {...register("shopName", {
                    required: "This field is required",
                    minLength: {
                      value: 4,
                      message: "Your store name must be at least 4 characters",
                    },
                    pattern: {
                      value: /[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?/i,
                      message:
                        "Only lowercase letters (a-z), numbers, and hyphens are allowed.",
                    },
                    validate: async (shopName) => {
                      const res = await fetch(
                        `${config.wpProtocol}//api.${config.wpDomain}/site-exists`,
                        {
                          method: "post",
                          body: JSON.stringify({
                            subdomain: shopName.toLowerCase(),
                          }),
                        }
                      );
                      const json = await res.json();

                      if (json.exists === false) return true;
                      return "A store with that URL already exists.";
                    },
                  })}
                  error={!!errors.shopName}
                  helperText={errors.shopName?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{`.${config.wpDomain}`}</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <MDTypography
                  variant="body2"
                  gutterBottom
                  textAlign="center"
                  pt={3}
                >
                  {"Having trouble? "}
                  <Link to="/support">We can help.</Link>
                </MDTypography>
                {isSubmitting && (
                  <MDTypography variant="body1" gutterBottom textAlign="center">
                    Just a moment while we set up your {config.name} account -
                    it may take up to 15 seconds.
                  </MDTypography>
                )}
              </Stack>
              <CardActions>
                <MDButton
                  variant="gradient"
                  type="submit"
                  color="dark"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <LoadingIcon sx={{ marginRight: 1 }} size={20} />
                  )}
                  Create your store
                </MDButton>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
