import api from "services/core/_api";

type TSettings = {
  blogname: string;
  currency: string;
  gmt_offset: string;
  site_icon: string;
};

export type TSettingsRequest = TSettings;

export type TSettingsResponse = TSettings;

const settings = {
  get: async (): Promise<TSettingsResponse> =>
    (await api.get("admin/settings")).data,
  post: async (data: TSettingsRequest): Promise<{}> =>
    (await api.post("admin/settings", data)).data,
};

export default settings;
