import PaddingIcon from "@mui/icons-material/Padding";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WidthFullIcon from "@mui/icons-material/WidthFull";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";

import BlocksEditor from "components/BlocksEditor/BlocksEditor";
import MDBox from "components/MDBox";
import SaveButton from "routes/u/Dashboard/SaveButton";
import layouts, { TLayout } from "services/core/layouts";
import SlidesEditor from "components/SlidesEditor/SlidesEditor";
import MDTypography from "components/MDTypography";

export default function Layouts({
  layout,
  onSubmit,
}: {
  layout: TLayout;
  onSubmit: () => void;
}) {
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<TLayout>({
    defaultValues: {
      blocks: layout.blocks,
      popup: layout.popup,
      header: layout.header,
    },
  });

  const handleSave = handleSubmit(async (data) => {
    await layouts.post(data);

    await onSubmit();
  });

  return (
    <Stack spacing={2}>
      <MDBox display="flex" alignItems="center">
        <WidthFullIcon fontSize="large" />
        <MDBox ml={2}>
          <MDTypography variant="h5" fontWeight="medium">
            Popup
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox
        bgColor="grey-100"
        borderRadius="lg"
        p={1}
        pl={3}
        pr={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <SlidesEditor control={control} name="popup.slides" />
      </MDBox>

      <MDBox display="flex" alignItems="center">
        <PaddingIcon fontSize="large" />
        <MDBox ml={2}>
          <MDTypography variant="h5" fontWeight="medium">
            Header
          </MDTypography>
        </MDBox>
      </MDBox>

      <BlocksEditor
        control={control}
        name="header.blocks"
        blockOptions={[
          { label: "Banner", value: { type: "banner", slides: [] } },
        ]}
      />

      <MDBox display="flex" alignItems="center">
        <ViewModuleIcon fontSize="large" />
        <MDBox ml={2}>
          <MDTypography variant="h5" fontWeight="medium">
            Layout
          </MDTypography>
        </MDBox>
      </MDBox>

      <BlocksEditor
        control={control}
        name="blocks"
        blockOptions={[
          { label: "Category", value: { type: "category" } },
          { label: "Banner", value: { type: "banner", slides: [] } },
        ]}
      />

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </Stack>
  );
}
