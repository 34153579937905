import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import LoadingIcon from "components/LoadingIcon";

import config from "config";

import { getSites, Site } from "libs/sites";
import { getSession } from "libs/session";
import { Session } from "@supabase/supabase-js";

const safeRedirect = (sites: Site[], requestedRedirectTo: string) => {
  const site = sites.find((site) => {
    const adminUrl = `${config.wpProtocol}//${site.subdomain}.${config.wpDomain}/wp-admin/`;
    return requestedRedirectTo.substring(0, adminUrl.length) === adminUrl;
  });

  if (!site) return null;

  return `${config.wpProtocol}//${site.subdomain}.${config.wpDomain}/wp-json/${config.server.shopApiNamespace}/admin/redirect`;
};

export default function Authorize() {
  const formRef = useRef<HTMLFormElement | null>(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [session, setSession] = useState<Session>();

  const requested_redirect_to = searchParams.get("requested_redirect_to");
  const requestedRedirectTo = requested_redirect_to
    ? decodeURIComponent(requested_redirect_to)
    : null;

  useEffect(() => {
    if (!formRef.current) return;

    if (!requestedRedirectTo) {
      navigate("/u", { replace: true });
      return;
    }

    (async () => {
      const session = await getSession();

      if (!session) {
        navigate("/signin", {
          replace: true,
          state: { requestedLocation: location },
        });
        return;
      }

      setSession(session);

      const sites = await getSites();

      if (!sites) return;

      const actionUrl = safeRedirect(sites, requestedRedirectTo);
      if (!actionUrl) {
        navigate("/u", { replace: true });
        return;
      }

      if (!formRef.current) return;

      formRef.current.action = actionUrl;
      formRef.current.submit();
    })();
  }, [location, navigate, requestedRedirectTo]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <LoadingIcon />
      <form ref={formRef} method="post">
        {session?.access_token !== null && (
          <input
            type="hidden"
            name="access_token"
            value={session?.access_token}
          />
        )}
        {requestedRedirectTo !== null && (
          <input
            type="hidden"
            name="requested_redirect_to"
            value={requestedRedirectTo}
          />
        )}
      </form>
    </Box>
  );
}
