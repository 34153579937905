import Box from "@mui/material/Box";

export default function RichTextEditorLabel({ label }: { label?: string }) {
  return (
    <>
      <Box
        component="label"
        lineHeight={1.5}
        fontSize="1rem"
        fontFamily={`"Roboto","Helvetica","Arial",sans-serif`}
        fontWeight={400}
        letterSpacing="0.00938em"
        p={0}
        color="#7b809a"
        display="block"
        sx={{
          transformOrigin: "top left",
          transform: "translate(14px, -9px) scale(0.75)",
        }}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        maxWidth="calc(133% - 32px)"
        position="absolute"
        left={0}
        top={0}
      >
        {label}
      </Box>
      <Box
        component="fieldset"
        position="absolute"
        top={-5}
        left={0}
        right={0}
        bottom={0}
        textAlign="left"
        m={0}
        p={0}
        px={1}
        sx={{ pointerEvents: "none" }}
        borderRadius="0.375rem"
        border="1px solid #d2d6da"
        minWidth={0}
        overflow="hidden"
        fontSize="0.875rem"
      >
        <Box
          component="legend"
          fontSize="0.85em"
          sx={{
            float: "unset",
            transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
          }}
          width="auto"
          overflow="hidden"
          display="block"
          p={0}
          height={11}
          visibility="hidden"
          maxWidth="100%"
          whiteSpace="nowrap"
        >
          <Box
            component="span"
            paddingLeft="5px"
            paddingRight="5px"
            display="inline-block"
            sx={{ opacity: 0 }}
            visibility="visible"
          >
            {label}
          </Box>
        </Box>
      </Box>
    </>
  );
}
