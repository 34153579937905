import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import DeleteIcon from "@mui/icons-material/Delete";
import EuroIcon from "@mui/icons-material/Euro";
import LanguageIcon from "@mui/icons-material/Language";
import StoreIcon from "@mui/icons-material/Store";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import SaveButton from "routes/u/Dashboard/SaveButton";

import settingsApi, { TSettingsRequest } from "services/core/settings";
import currencies from "./currencies";
import manualOffsets from "./manualOffsets";
import AddSlideButton from "components/SlidesEditor/AddSlideButton";
import FixHeightWPImage from "components/FixHeightWPImage";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const height = 120;

export default function SettingsForm({
  settings,
  onSubmit,
}: {
  settings: TSettingsRequest;
  onSubmit: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    control,
    reset,
  } = useForm({
    defaultValues: {
      blogname: settings.blogname,
      currency: settings.currency,
      gmt_offset: settings.gmt_offset,
      site_icon: settings.site_icon,
    },
  });

  const handleSave = handleSubmit(async (data) => {
    // send
    await settingsApi.post(data);
    await onSubmit();
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset(settings);
  }, [settings, isSubmitSuccessful, reset]);

  return (
    <MDBox>
      <MDBox display="flex" mb={1}>
        <StoreIcon fontSize="large" />

        <TextField
          sx={{ ml: 2 }}
          margin="dense"
          label="Store name"
          {...register("blogname", {
            required: "This field is required",
          })}
          error={!!errors.blogname}
          helperText={errors.blogname?.message}
          fullWidth
        />
      </MDBox>

      <MDBox display="flex" mb={1}>
        <LanguageIcon fontSize="large" />

        <Controller
          name="gmt_offset"
          rules={{
            required: "This field is required",
          }}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              value={manualOffsets.find((o) => o.value === value)}
              onChange={(e, data) => onChange(data?.value)}
              sx={{ ml: 2 }}
              disablePortal
              options={manualOffsets}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Timezone"
                  error={!!errors.gmt_offset}
                  helperText={errors.gmt_offset?.message}
                />
              )}
            />
          )}
          control={control}
        />
      </MDBox>

      <MDBox display="flex" mb={1}>
        <EuroIcon fontSize="large" />

        <Controller
          name="currency"
          rules={{
            required: "This field is required",
          }}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              value={value}
              onChange={(e, data) => onChange(data)}
              sx={{ ml: 2 }}
              disablePortal
              options={currencies}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Currency"
                  error={!!errors.currency}
                  helperText={errors.currency?.message}
                />
              )}
            />
          )}
          control={control}
        />
      </MDBox>

      <MDBox display="flex" mb={1}>
        <AppShortcutIcon fontSize="large" />

        <Box ml={2}>
          <MDTypography variant="h5" fontWeight="medium">
            Logo
          </MDTypography>

          <Controller
            name="site_icon"
            rules={{
              required: "This field is required",
            }}
            render={({ field: { onChange, value } }) => (
              <Box mt={2}>
                {value !== "0" ? (
                  <Box
                    gap={2}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <FixHeightWPImage
                      id={parseInt(value)}
                      size="woocommerce_single"
                      height={height}
                    />
                    <MDButton
                      onClick={() => onChange("0")}
                      variant="text"
                      color="warning"
                      size="small"
                      startIcon={<DeleteIcon />}
                      sx={{ lineHeight: 1.5 }}
                    >
                      Delete
                    </MDButton>
                  </Box>
                ) : (
                  <Box m={-2}>
                    <AddSlideButton
                      onUploaded={(ids) => {
                        onChange(`${ids[0]}`);
                      }}
                      height={height}
                      multiple={false}
                    />
                  </Box>
                )}
              </Box>
            )}
            control={control}
          />
        </Box>
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <SaveButton onClick={handleSave} loading={isSubmitting} />
      </MDBox>
    </MDBox>
  );
}
