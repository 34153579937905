import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function DashboardSection({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
}) {
  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <MDBox p={3} pb={2} lineHeight={1}>
              <MDBox mb={1}>
                <MDTypography variant="h5">{title}</MDTypography>
              </MDBox>
              <MDTypography variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>

            <MDBox pb={3} px={3}>
              {children}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
