import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import config from "../config";
import supabase from "../libs/supabase";

import LoadingIcon from "components/LoadingIcon";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export default function ResetPassword() {
  const navigate = useNavigate();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const defaultValues = {
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const handleResetPassword = handleSubmit(async ({ password }) => {
    const { error } = await supabase.auth.updateUser({
      password,
    });

    if (error) {
      setErrorMsg(error.message);
      setOpenErrorAlert(true);
      console.error(error);
      return;
    }

    navigate("/u");
  });

  return (
    <Grid
      p={1}
      pt={11}
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} sm={8} md={6}>
        <Snackbar
          open={openErrorAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setOpenErrorAlert(false)}
        >
          <Alert
            onClose={() => setOpenErrorAlert(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardContent>
            <form onSubmit={handleResetPassword}>
              <Stack spacing={1}>
                <MDTypography
                  variant="h3"
                  gutterBottom
                  fontWeight="bold"
                  textAlign="center"
                >
                  Reset your password
                </MDTypography>

                <TextField
                  label="Enter a new password"
                  variant="standard"
                  type="password"
                  {...register("password", {
                    required: "This field is required",
                  })}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                />

                <MDTypography variant="button" gutterBottom py={2}>
                  {`If you’d like some extra help, just email our support team at `}
                  <Link href={`mailto:${config.supportEmail}`}>
                    {config.supportEmail}
                  </Link>
                </MDTypography>
              </Stack>

              <CardActions>
                <MDButton
                  variant="gradient"
                  color="dark"
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <LoadingIcon sx={{ marginRight: 1 }} size={20} />
                  )}
                  Save my password
                </MDButton>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
