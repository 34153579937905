import { TImageMetadata } from "services/types";
import api from "services/wp/_api";

type MediaResponse = {
  id: number;
  media_details: TImageMetadata;
  source_url: string;
};

const media = {
  post: async (data: FormData): Promise<MediaResponse> =>
    (
      await api.post("media", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    ).data,
};

export default media;
