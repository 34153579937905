import MDBox from "components/MDBox";
import LoadingIcon from "components/LoadingIcon";

import DashboardSection from "../DashboardSection";

import useApi from "libs/useApi";
import site from "services/core/site";
import SiteForm from "./SiteForm";

export default function Site() {
  const { data, refresh } = useApi(site.get);

  return (
    <DashboardSection
      title="Platform Status"
      description="Configure Platform Availability"
    >
      {data ? (
        <SiteForm data={data} onSubmit={refresh} />
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <LoadingIcon />
        </MDBox>
      )}
    </DashboardSection>
  );
}
